<template>
      <div class="row">
          <div class="col-md-3">
             <HomeCard :total="store.state.user.totals.expedients" title="Expedientes" url="Expedients">
                 <template #icon>
                   <img width=""  alt="expedients" src="@/assets/img/expedients.svg">

                 </template>
             </HomeCard>
          </div>
        <div class="col-md-3">
          <HomeCard :total="store.state.user.totals.admins" title="Profesionales" url="Admins">
            <template #icon>
              <img width=""  alt="expedients" src="@/assets/img/users-white.svg">

            </template>
          </HomeCard>
        </div>
        <div class="col-md-3">
          <HomeCard :total="store.state.user.totals.children" title="Menores" url="Children">
            <template #icon>
              <el-icon :size="50" >
                <User />
              </el-icon>

            </template>
          </HomeCard>
        </div>
      </div>
</template>

<script setup>

import HomeCard from "@/views/dashboard/HomeCard.vue";
import {onMounted} from "vue";
import {useStore} from "vuex";
import {User} from "@element-plus/icons-vue";

const store = useStore()

onMounted(()=>{
    store.dispatch('user/getTotals')
})
</script>

<style scoped>

</style>