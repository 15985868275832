<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">Datos personales</h3>
          </div>
          <div class="card-body">
            <label for="name" class="form-control-label">
              Nombre<span class="required">*</span>
            </label>

            <input
                id="name"
                v-model="formModel.firstName"
                :class="[
                        'form-control',
                        v.firstName.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.firstName.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.firstName.$errors[0].$message }}
              </small>
            </p>
            <label for="lastname" class="form-control-label">
              Apellidos<span class="required">*</span>
            </label>

            <input
                id="name"
                v-model="formModel.lastName"
                :class="[
                        'form-control',
                        v.lastName.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.lastName.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.lastName.$errors[0].$message }}
              </small>
            </p>
            <label for="dni" class="form-control-label">
              DNI
            </label>

            <input
                id="dni"
                v-model="formModel.personalInfo.dni"
                :class="[
                        'form-control',
                        v.personalInfo.dni.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.personalInfo.dni.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.personalInfo.dni.$errors[0].$message }}
              </small>
            </p>
            <label for="gender" class="form-control-label w-100">
              Género<span class="required">*</span>
            </label>

            <el-select
                v-model="formModel.personalInfo.gender"
                size="large"
                class="w-100"
            >
              <el-option
                  v-for="selectItem in genders"
                  :key="selectItem.id"
                  :value="selectItem.id"
                  :label="selectItem.name"
              />
            </el-select>
            <p v-if="v.personalInfo.gender.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.personalInfo.gender.$errors[0].$message }}
              </small>
            </p>
            <label for="date_of_birth" class="form-control-label">
              Fecha de nacimiento<span class="required">*</span>
            </label>
            <el-date-picker
                class="w-100 d-block mb-2"
                v-model="formModel.personalInfo.dateOfBirth"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="2023/08/16"
                :dayjs="Dayjs"
            />
            <p v-if="v.personalInfo.dateOfBirth.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.personalInfo.dateOfBirth.$errors[0].$message }}
              </small>
            </p>
            <label for="photo" class="form-control-label">
              Foto
            </label>
            <input
                type="file"
                id="photo"
                @change="uploadFile"
                :class="[ 'form-control']"
                placeholder=""
            />
            <p v-if="v.photo.$errors.length" class="invalid-feedback mb-0">
              <small>
                {{ v.photo.$errors[0].$message }}
              </small>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">Dirección</h3>
          </div>
          <div class="card-body">
            <label for="address_street" class="form-control-label">
              Calle<span class="required">*</span>
            </label>
            <input
                id="address_street"
                v-model="formModel.address.street"
                :class="[
                        'form-control',
                        v.address.street.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.street.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.street.$errors[0].$message }}
              </small>
            </p>

            <label for="address_number" class="form-control-label">
              Número<span class="required">*</span>
            </label>

            <input
                id="address_number"
                v-model="formModel.address.number"
                :class="[
                        'form-control',
                        v.address.number.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.number.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.number.$errors[0].$message }}
              </small>
            </p>

            <label for="address_door" class="form-control-label">
              Puerta
            </label>

            <input
                id="address_number"
                v-model="formModel.address.door"
                :class="[
                        'form-control',
                        v.address.door.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.door.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.door.$errors[0].$message }}
              </small>
            </p>

            <label for="address_floor" class="form-control-label">
              Piso
            </label>

            <input
                id="address_floor"
                v-model="formModel.address.floor"
                :class="[
                        'form-control',
                        v.address.floor.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.floor.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.floor.$errors[0].$message }}
              </small>
            </p>

            <label for="address_ladder" class="form-control-label">
              Escalera
            </label>

            <input
                id="address_ladder"
                v-model="formModel.address.ladder"
                :class="[
                        'form-control',
                        v.address.ladder.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.ladder.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.ladder.$errors[0].$message }}
              </small>
            </p>

            <label for="address_zip_code" class="form-control-label">
              Código postal<span class="required">*</span>
            </label>

            <input
                id="address_zip_code"
                v-model="formModel.address.zipCode"
                :class="[
                        'form-control',
                        v.address.zipCode.$error ? 'is-invalid' : '',
                      ]"
                type="text"
                placeholder=""
            />

            <p v-if="v.address.zipCode.$error" class="invalid-feedback mb-0">
              <small>
                {{ v.address.zipCode.$errors[0].$message }}
              </small>
            </p>
            <el-row :gutter="10">
              <el-col :span="12">
                <label for="province" class="form-control-label w-100">
                  Comunidad<span class="required">*</span>
                </label>
                <el-select  clearable  v-model="formModel.community"  placeholder="Comunidad" size="large">
                  <el-option
                      v-for="selectItem in communities"
                      :key="selectItem.id"
                      :value="selectItem['@id']"
                      :label="selectItem.name"
                  />
                </el-select>
                <p v-if="v.community.$error" class="invalid-feedback mb-0">
                  <small>
                    {{ v.community.$errors[0].$message }}
                  </small>
                </p>
                <template v-if="formModel.province">
                  <label for="locality" class="form-control-label w-100">
                    Localidad<span class="required">*</span>
                  </label>
                  <input
                      style="max-width: 240px;"
                      id="locality"
                      v-model="formModel.locality"
                      :class="[
                        'form-control',
                        v.locality.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                  />

                  <p v-if="v.locality.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.locality.$errors[0].$message }}
                    </small>
                  </p>

                </template>
              </el-col>
              <el-col :span="12">
                <template v-if="formModel.community">
                  <label for="province" class="form-control-label w-100">
                    Provincia<span class="required">*</span>
                  </label>
                  <el-select  clearable  v-model="formModel.province" class="w-100" placeholder="Provincia" size="large">
                    <el-option
                        v-for="selectItem in provinces"
                        :key="selectItem.id"
                        :value="selectItem['@id']"
                        :label="selectItem.name"
                    />
                  </el-select>
                  <p v-if="v.province.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.province.$errors[0].$message }}
                    </small>
                  </p>
                </template>
              </el-col>
            </el-row>
          </div>
        </div>

      </div>
      <div class="mt-3">
        <button
            type="submit"
            class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
          {{ buttonText }}
        </button>

        <button
            class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
            @click.prevent="handleCancel()"
        >
          Cancelar
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, defineEmits,onMounted} from "vue";
import {useStore} from "vuex";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, minLength, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {clearErrors} from "@/utils/errorsUtils";
import {genderList} from "@/utils/genders";

const store = useStore();
const emit = defineEmits(['closeFrom'])
const props = defineProps({
  action: {
    type: String,
    required: true
  },
  entity: {
    type: Object,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
});

const formModel = ref( {
  expedient: props.entity['@id'],
  firstName: null,
  lastName: null,
  email: null,
  city: null,
  personalInfo: {},
  address: {}
});
const communities = computed(() => store.state.community.entities);
const provinces = computed(() => {
  let provinces = store.state.province.entities
  if(formModel.value.community){
    return provinces.filter(item =>{
      return item.community['@id'] === formModel.value.community
    })
  }
  return []
});
const genders = ref(genderList)

onMounted(async () => {
  await store.dispatch("community/getEntities");
  await store.dispatch("province/getEntities");
});

const rules = computed(() => {
  return {
    firstName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    personalInfo: {
      dni: {},
      gender: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      dateOfBirth: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
    },
    address: {
      street: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      number: {
        required: helpers.withMessage("Este campo es obligatorio", required),
      },
      door: {
        // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      floor: {
        // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      ladder: {
        // required: helpers.withMessage("Este campo es obligatorio", required),
      },
      zipCode: {
        required: helpers.withMessage("Este campo es obligatorio", required),
        minLength: helpers.withMessage("Este campo es obligatorio", minLength(5)),
        maxLength: helpers.withMessage("Este campo es obligatorio", maxLength(5)),
      },
    },
    community: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    province: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    city: {
      // required: helpers.withMessage("Este campo es obligatorio", required),
    },
    locality: {
      // required: helpers.withMessage("Este campo es obligatorio", required),
    },
    photo: {

    }

  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const buttonText = computed(
    () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.firstName = null
  formModel.value.lastName = null
  formModel.value.personalInfo = {}
  formModel.value.address = {}
  formModel.value.city = {}
};

const handleCancel = () => {
  clearForm()
  emit('closeFrom');
};

const updateEntity = async (data) => {
  try {
    await store.dispatch("child/edit", data).then(async (res) => {
      if (res.statusCode && (res.statusCode === 200 || res.statusCode === 201)) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Menor editado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
        emit('closeFrom')
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }

    });
    clearForm()
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });
  }
};

const createEntity = async (data) => {
  await store.dispatch("child/add", data).then(async (res) => {
    if(!res.response){
      emit('closeFrom')
      clearForm()
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Menor creado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
    }else{
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: res.response.data.detail,
        showConfirmButton: false,
        timer: 3000
      });
    }

  })
};

const uploadFile = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.readAsDataURL(files[i]);
    reader.onload = function(e) {
      formModel.value.photo = e.target.result;
    };
  }
}
</script>
