//import jwtDecode from 'jwt-decode';

export const currentState = (state) => {
    return state.isAuthenticated
}

export const isAuthenticated = (state) => {
    if (sessionStorage.getItem('isAuthenticated')){
        return sessionStorage.getItem('isAuthenticated')
    }
    return state.isAuthenticated
}

export const username = (state) => {
    return state.user?.name || ''
}

export const getUser = (state) => {
    return state.user
}

export const getRole = () => {
    return sessionStorage.getItem('userRole');

}

export const getUserId = (state) => {
    if(state.userId){
        return state.userId
    }
    return sessionStorage.getItem('user').id;
}