<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="title" class="form-control-label">
          Título<span class="required">*</span>
        </label>

        <input
          id="name"
          v-model="formModel.title"
          :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />

        <p v-if="v.title.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.title.$errors[0].$message }}
          </small>
        </p>
        <label for="description" class="form-control-label">
          Descripción<span class="required">*</span>
        </label>

        <input
            id="description"
            v-model="formModel.description"
            :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
            type="text"
            placeholder=""
        />

        <p v-if="v.description.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.description.$errors[0].$message }}
          </small>
        </p>
        <label for="title" class="form-control-label">
          Título<span class="required">*</span>
        </label>

        <input
            id="location"
            v-model="formModel.location"
            :class="[
                        'form-control',
                        v.location.$error ? 'is-invalid' : '',
                      ]"
            type="text"
            placeholder=""
        />

        <p v-if="v.location.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.location.$errors[0].$message }}
          </small>
        </p>
        <label for="expedient" class="form-control-label w-100">
          Expediente<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.expedient"
            size="large"
        >
          <el-option
              v-for="selectItem in expedients"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.child.fullName"
          />

        </el-select>
        <p v-if="v.expedient.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.expedient.$errors[0].$message }}
          </small>
        </p>
        <label for="category" class="form-control-label w-100">
          Categoría<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.category"
            size="large"
        >
          <el-option
              v-for="selectItem in categories"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.name"
          />
        </el-select>
        <p v-if="v.category.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.category.$errors[0].$message }}
          </small>
        </p>
        <label for="date_time" class="form-control-label" style="margin-right: 5px;margin-top: 5px;display: block;">
          Fecha
        </label>
        <el-date-picker
            v-model="formModel.dueDate"
            type="datetime"
            value-format="YYYY-MM-DD H:i"
            placeholder="2023/08/16"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            :dayjs="Dayjs"
            :class="v.date_time.$errors.length >0 ? 'is-invalid' : ''"
        />
        <p v-if="v.date_time.$errors.length >0" class="invalid-feedback  invalid-field mb-0">
          <small>
            {{ v.date_time.$errors[0].$message }}
          </small>
        </p>
    </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click.prevent="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import 'dayjs/locale/en';
import {ElLoading} from "element-plus";


const store = useStore();
const router = useRouter();
const expedients = computed(() => store.state.expedient.entities.data);
const categories = computed(() => store.state.eventCategory.entities.data);

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {});

onMounted(() => {
  store.dispatch("eventCategory/getEntitiesNoPaginate");
  store.dispatch("expedient/getEntities");
})
const rules = computed(() => {
  return {
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    category: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    location: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    frequency: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    date_time: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    type: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.title = null
  formModel.value.category = null
  formModel.value.dateTime = null
  formModel.value.children = []
  formModel.value.endDateTime = null
  formModel.value.location = null
  formModel.value.description = null
  formModel.value.frequency = null
  v.$reset()
};


const handleCancel = () => {
  router.push({ name: 'Events', params: {} });
};

const updateEntity = async (data) => {
  try {
    await store.dispatch("event/edit", data).then(() =>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Evento editado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    });
    clearForm()
    await router.push({ name: 'Events', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch("event/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Evento creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        clearForm()
        await router.push({name: 'Events', params: {}});
      } else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Se ha producido un error.',
          showConfirmButton: false,
          timer: 3000
        });
      }
    });
};

</script>
