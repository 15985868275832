export const parseApiErrors = (error) => {
    return error.response.body.violations.reduce(
        (parsedErrors, violation) => {
            parsedErrors[violation['propertyPath']] = violation['message'];
            return parsedErrors;
        }
    , {});
};


export const clearErrors = (error) => {
    if(typeof error === 'string'){
        return error
    }
    let arrayValues = error.response.data.detail.split(':')
    let result = arrayValues[arrayValues.length-1]
    if(result.includes('Duplicate entry')){
        let val = result.split('Duplicate entry')[1]
        return 'Este valor ya esta en uso: ' + val.split(' ')[1]
    }
    return result
};