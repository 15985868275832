<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Notificaciones
                </h3>

                <div class="sub-line"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-12 py-2">
                <el-input
                    clearable
                    type="text"
                    placeholder="Título"
                    v-model="searchTitle"
                />
              </div>
              <div class="col-md-3">
                <button
                    @click="handleSearch"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border
                      hover:bg-blue-900 border-1
                      mt-2
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Título
                    </th>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Contenido
                    </th>
                    <td class="d-none d-lg-table-cell text-center"></td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in entities" :key="item.id">
                    <td class="d-none d-lg-table-cell">
                      {{ item.title }}
                    </td>
                    <td class="d-none d-lg-table-cell">
                    {{ item.body }}
                    </td>
                    <td class="d-none d-lg-table-cell text-center">
                      <a
                        class="btn btn-link icon-danger px-3 mb-0 rounded text-danger"
                        href="javascript:;"
                        @click="handleDelete(item.id)"
                      >
                        <el-icon :size="25"
                                 color="#fff">
                          <Delete />
                        </el-icon>
                         Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import { ref } from "vue";
import {Delete} from "@element-plus/icons-vue";

const store = useStore();
const searchTitle = ref("");


const handleSearch = () => {
  let payload = {
    'user.id':  store.getters["user/getUser"].id
  }
  if(searchTitle.value){
    payload.title = searchTitle.value
  }
  store.dispatch("notification/getEntities", payload);
};

const getEntities = async () => {
  let payload = {
    'user.id':  store.getters["user/getUser"].id
  }
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
   await store.dispatch("notification/getEntities", payload).then(()=> {
     spin.close()
   });
};

onMounted(()=> {
  getEntities();
});

const entities = computed(
  () => store.state.notification.entities.data
);

const deleteEntity = async (position) => {
  try {
    await store.dispatch("notification/deleteItem", position).then(async () => {
      await getEntities();
    });

  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar la Notificación?")
    .then(() => {
      deleteEntity(item);
    })
    .catch((error) => {
      console.error(error);
    });
};
</script>
