import { createRouter, createWebHistory } from "vue-router";
import UserList from "../views/user/UserList.vue";
import UserUpdate from "../views/user/UserUpdate.vue";
import UserCreate from "../views/user/UserCreate.vue";
import ChangePassword from "../views/user/ChangePassword.vue";
import UserProfile from "../views/user/UserProfile.vue";
import NoPageFound from "../views/NoPageFound.vue";
import Dashboard from "../views/dashboard/Dashboard";
import { isAuthenticatedGuard, isTotal} from "@/services/authGuard";
import RecuperatePassword from "../views/user/RecuperatePassword.vue";
import ChangeUserPassword from "../views/user/ChangeUserPassword.vue";
import ExpedientList from "../views/expedient/ExpedientList.vue";
import ParentList from "../views/parent/ParentList.vue";
import ChildList from "../views/child/ChildList.vue";
import ChildCreate from "../views/child/ChildCreate.vue";
import ChildUpdate from "../views/child/ChildUpdate.vue";
import ParentCreate from "../views/parent/ParentCreate.vue";
import ParentUpdate from "../views/parent/ParentUpdate.vue";
import ChildDetails from "../views/child/ChildDetails.vue";
import ParentDetails from "../views/parent/ParentDetails.vue";
import EventDetails from "../views/event/EventDetails.vue";
import EventCreate from "../views/event/EventCreate.vue";
import EventUpdate from "../views/event/EventUpdate.vue";
import EventList from "../views/event/EventList.vue";
import ChatUpdate from "../views/chat/ChatUpdate.vue";
import ChatList from "../views/chat/ChatList.vue";
import ChatDetails from "../views/chat/ChatDetails.vue";
import ChatCreate from "../views/chat/ChatCreate.vue";
import DocumentList from "../views/document/DocumentList.vue";
import DocumentCreate from "../views/document/DocumentCreate.vue";
import DocumentDetails from "../views/document/DocumentDetails.vue";
import DocumentUpdate from "../views/document/DocumentUpdate.vue";
import DocumentCategoryCreate from "../views/document-category/DocumentCategoryCreate.vue";
import DocumentCategoryDetails from "../views/document-category/DocumentCategoryDetails.vue";
import DocumentCategoryUpdate from "../views/document-category/DocumentCategoryUpdate.vue";
import DocumentCategoryList from "../views/document-category/DocumentCategoryList.vue";
import RequestList from "../views/request/RequestList.vue";
import RequestUpdate from "../views/request/RequestUpdate.vue";
import RequestDetails from "../views/request/RequestDetails.vue";
import RequestCreate from "../views/request/RequestCreate.vue";
import AgreementUpdate from "../views/agreements/AgreementUpdate.vue";
import AgreementList from "../views/agreements/AgreementList.vue";
import AgreementDetails from "../views/agreements/AgreementDetails.vue";
import AgreementCreate from "../views/agreements/AgreementCreate.vue";
import SentenceList from "../views/sentence/SentenceList.vue";
import SentenceUpdate from "../views/sentence/SentenceUpdate.vue";
import SentenceDetails from "../views/sentence/SentenceDetails.vue";
import SentenceCreate from "../views/sentence/SentenceCreate.vue";
import SuperAdminList from "../views/user/SuperAdminList.vue";
import AdminList from "../views/user/AdminList.vue";
import ExpedientUpdate from "../views/expedient/ExpedientUpdate.vue";
import ExpedientDetails from "../views/expedient/ExpedientDetails.vue";
import AdminExpedientDetails from "../views/expedient/AdminExpedientDetails.vue";
import ExpedientCreate from "../views/expedient/ExpedientCreate.vue";
import EventCategoryCreate from "@/views/event-category/EventCategoryCreate.vue";
import EventCategoryDetails from "@/views/event-category/EventCategoryDetails.vue";
import EventCategoryUpdate from "@/views/event-category/EventCategoryUpdate.vue";
import EventCategoryList from "@/views/event-category/EventCategoryList.vue";
import MeetingUpdate from "@/views/meeting/MeetingUpdate.vue";
import MeetingtList from "@/views/meeting/MeetingtList.vue";
import MeetingDetails from "@/views/meeting/MeetingDetails.vue";
import MeetingCreate from "@/views/meeting/MeetingCreate.vue";
import NotificationList from "@/views/notifications/NotificationList.vue";
import SuperCreate from "@/views/user/SuperCreate.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginUser'),
    },
    {
        path: "/confirm/recuperate-password/:token",
        name: "Recuperar-Contraseña",
        component: RecuperatePassword
    },
    {
        path: '/',
        name: 'Front',
        component: () => import('../layouts/MainLayout'),
        children: [
            {
                path: "/",
                name: "Dashboard",
                component: Dashboard,
                beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "/users",
                name: "Usuarios",
                component: UserList,
               // beforeEnter: [isSuperAdmin]
            },
            {
                path: "/superadmins",
                name: "SuperAdmins",
                component: SuperAdminList,
                // beforeEnter: [isSuperAdmin]
            },
            {
                path: "/admins",
                name: "Admins",
                component: AdminList,
                // beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/:id",
                name: "Actualizar-Usuario",
                component: UserUpdate,
              //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/admin/:id",
                name: "Actualizar-Admin",
                component: UserUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/add",
                name: "Crear-Usuario",
                component: UserCreate,
             //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/admin/add",
                name: "Crear-Admin",
                component: UserCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "users/super/add",
                name: "Crear-Super",
                component: SuperCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "user/profile/edit/:id",
                name: "Editar-Perfil",
                component: UserProfile
            },
            {
                path: "notifications",
                name: "Notifications",
                component: NotificationList

            },
            {
                path: "users/edit/:id",
                name: "Cambiar-Contraseña",
                component: ChangePassword
            },
            {
                path: "users/change-password/:id",
                name: "Cambiar-Contraseña-Usuario",
                component: ChangeUserPassword
            },
            {
                path: '/expedients',
                name: 'Expedients',
                component: ExpedientList,
                //beforeEnter: [isSuperAdmin]
            },
            {
                path: "expedients/:id",
                name: "Actualizar-Expedient",
                component: ExpedientUpdate,
                beforeEnter: [isTotal]
            },
            {
                path: "expedients/detail/:id",
                name: "Detalle-Expedient",
                component: ExpedientDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "expedients-admin/detail/:id",
                name: "Detalle-Expedient-Admin",
                component: AdminExpedientDetails,
                beforeEnter: [isTotal]
            },
            {
                path: "expedients/add",
                name: "Crear-Expedient",
                component: ExpedientCreate,
                beforeEnter: [isTotal]
            },
            {
                path: '/parents',
                name: 'Parents',
                component: ParentList,
                beforeEnter: [isTotal]
            },
            {
                path: "parents/:id",
                name: "Actualizar-Parent",
                component: ParentUpdate,
            //    beforeEnter: [isSuperAdmin]
            },
            {
                path: "parents/detail/:id",
                name: "Detalle-Parent",
                component: ParentDetails,
            //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "parents/add",
                name: "Crear-Parent",
                component: ParentCreate,
            //    beforeEnter: [isSuperAdmin]
            },
            {
                path: '/children',
                name: 'Children',
                component: ChildList,
                beforeEnter: [isTotal ]
            },
            {
                path: "children/:id",
                name: "Actualizar-Child",
                component: ChildUpdate,
                beforeEnter: [isTotal]
            },
            {
                path: "children/detail/:id",
                name: "Detalle-Child",
                component: ChildDetails,
            //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "children/add",
                name: "Crear-Child",
                component: ChildCreate,
                beforeEnter: [isTotal]
            },
            {
                path: '/events',
                name: 'Events',
                component: EventList,
             //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "events/:id",
                name: "Actualizar-Event",
                component: EventUpdate,
              //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "events/detail/:id",
                name: "Detalle-Event",
                component: EventDetails,
            //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "events/add",
                name: "Crear-Event",
                component: EventCreate,
            //    beforeEnter: [isSuperAdmin]
            },
            {
                path: '/chats',
                name: 'Chats',
                component: ChatList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "chats/:id",
                name: "Actualizar-Chat",
                component: ChatUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "chats/detail/:id",
                name: "Detalle-Chat",
                component: ChatDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "chats/add",
                name: "Crear-Chat",
                component: ChatCreate,
                //    beforeEnter: [isSuperAdmin]
            },
            {
                path: '/documents',
                name: 'Documents',
                component: DocumentList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "documents/:id",
                name: "Actualizar-Document",
                component: DocumentUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "documents/detail/:id",
                name: "Detalle-Document",
                component: DocumentDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "documents/add",
                name: "Crear-Document",
                component: DocumentCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/document-categories',
                name: 'DocumentCategories',
                component: DocumentCategoryList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "document-categories/:id",
                name: "Actualizar-DocumentCategory",
                component: DocumentCategoryUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "document-categories/detail/:id",
                name: "Detalle-DocumentCategory",
                component: DocumentCategoryDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "document-categories/add",
                name: "Crear-DocumentCategory",
                component: DocumentCategoryCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/request',
                name: 'Requests',
                component: RequestList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "request/:id",
                name: "Actualizar-Request",
                component: RequestUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "request/detail/:id",
                name: "Detalle-Request",
                component: RequestDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "request/add",
                name: "Crear-Request",
                component: RequestCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/agreements',
                name: 'Agreements',
                component: AgreementList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "agreements/:id",
                name: "Actualizar-Agreement",
                component: AgreementUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "agreements/detail/:id",
                name: "Detalle-Agreement",
                component: AgreementDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "agreements/add",
                name: "Crear-Agreement",
                component: AgreementCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/sentences',
                name: 'Sentences',
                component: SentenceList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "sentences/:id",
                name: "Actualizar-Sentence",
                component: SentenceUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "sentences/detail/:id",
                name: "Detalle-Sentence",
                component: SentenceDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "sentences/add",
                name: "Crear-Sentence",
                component: SentenceCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/event-categories',
                name: 'EventCategoryList',
                component: EventCategoryList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "event-categories/:id",
                name: "Actualizar-EventCategory",
                component: EventCategoryUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "event-categories/detail/:id",
                name: "Detalle-EventCategory",
                component: EventCategoryDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "event-categories/add",
                name: "Crear-EventCategory",
                component: EventCategoryCreate,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: '/meetings',
                name: 'Meetings',
                component: MeetingtList,
                //   beforeEnter: [isSuperAdmin]
            },
            {
                path: "meetings/:id",
                name: "Actualizar-Meeting",
                component: MeetingUpdate,
                //  beforeEnter: [isSuperAdmin]
            },
            {
                path: "meetings/detail/:id",
                name: "Detalle-Meeting",
                component: MeetingDetails,
                //    beforeEnter: [isAuthenticatedGuard]
            },
            {
                path: "meetings/add",
                name: "Crear-Meeting",
                component: MeetingCreate,
                //   beforeEnter: [isSuperAdmin]
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: NoPageFound
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

export default router;
