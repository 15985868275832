<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title mb-0">
                {{ currentEntity.fullName }}
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Parents' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm text-white border-1 bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row">
               <div class="col-md-6">
                 <div class="card">
                   <div class="card-header">
                       <h3 class="card-title">Datos personales</h3>
                   </div>
                    <div class="card-body" v-if="currentEntity">
                      <div class="col-12">
                        <img width="100" height="100" class="rounded-circle" v-if="currentEntity.photoPath" :src="imageUrl +currentEntity.photoPath" alt="">
                        <img width="30" height="30" v-else src="@/assets/img/user.svg" alt="">
                        <p>{{ currentEntity.fullName }}</p>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <label for="gender" class="form-control-label col-12">
                            Género<span class="required">*</span>
                          </label>
                          <Gender :value="currentEntity.personalInfo.gender" />
                        </div>
                        <div class="col-md-6">
                          <label for="date_of_birth" class="form-control-label">
                            Fecha de nacimiento<span class="required">*</span>
                          </label>
                          {{ currentEntity.personalInfo.dateOfBirth }}
                        </div>

                      </div>
                      <div class="row mt-2">
                        <div class="col-md-6">
                          <label for="dni" class="form-control-label col-12">
                            DNI<span class="required">*</span>
                          </label>
                          {{ currentEntity.personalInfo.dni }}
                        </div>
                      </div>
                      </div>
                    </div>
                 </div>
                 <div class="col-md-6">
                   <div class="card">
                     <div class="card-header">
                       <h3 class="card-title">Dirección</h3>
                     </div>
                     <div class="card-body">
                       <label for="address.street" class="form-control-label">
                         Calle<span class="required">*</span>
                       </label>
                       <p>{{ currentEntity.address.street }}</p>

                       <label for="address.number" class="form-control-label">
                         Número<span class="required">*</span>
                       </label>
                       <p>{{ currentEntity.address.number }}</p>

                       <label for="address.door" class="form-control-label">
                         Puerta
                       </label>
                       <p>{{ currentEntity.address.door }}</p>

                       <label for="address.floor" class="form-control-label">
                         Piso
                       </label>
                       <p>{{ currentEntity.address.floor }}</p>

                       <label for="address.ladder" class="form-control-label">
                         Escalera
                       </label>
                       <p>{{ currentEntity.address.ladder }}</p>

                       <label for="address.zipCode" class="form-control-label">
                         Código postal<span class="required">*</span>
                       </label>
                       <p>{{ currentEntity.address.zipCode }}</p>
                       <label  class="form-control-label col-12 mt-2">
                         Comunidad
                       </label>
                       <p v-if="currentEntity.province">{{ currentEntity.province.community.name }}</p>
                       <label  class="form-control-label col-12 mt-2">
                         Provincia
                       </label>
                       <p v-if="currentEntity.province">{{ currentEntity.province.name }}</p>
                       <label class="form-control-label">
                         Localidad<span class="required">*</span>
                       </label>
                       <p>{{ currentEntity.locality }}</p>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted, ref} from "vue";
import { useRoute } from "vue-router";
import Gender from "@/components/Gender.vue";
import {ElLoading} from "element-plus";

const route = useRoute();
const store = useStore();
const imageUrl = ref(process.env.VUE_APP_URL+'/images/')
const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch('parent/getEntityById', id).finally(()=>spin.close())
};

const currentEntity = computed(() => store.state.parent.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});
</script>
