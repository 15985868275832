<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title text-white mb-0">
                Nuevo Acuerdo
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <agreement-form :entity="formModel" action="create" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import AgreementForm from "./AgreementForm.vue";

const formModel = ref({
  expedient: null,
  authorParent: null,
  document: null,
  endorsedByAuthor: false,
  endorsedByOtherParent: false,
});
</script>
