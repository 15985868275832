<template>

  <div v-if="currentEntity" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Editar Acuerdo
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <agreement-form :entity="currentEntity" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import AgreementForm from "./AgreementForm.vue";
import {ElLoading} from "element-plus";

const store = useStore();
const route = useRoute();

const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("agreement/getEntityById", id).finally(()=>spin.close());
};

const currentEntity = computed(() => {
  return store.state.agreement.currentEntity

});

onMounted(()=>{
  getCurrentEntity( route.params.id);
});
</script>
