
export const setEntities = (state, communities) => {
    state.entities = communities
    state.loading = false
}

export const setPagination = (state, pagination) => {
    state.pagination = pagination;
    state.loading = false;
};

export const setCurrentEntity = (state, community) => {
    state.currentEntity = community
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}
