import axios from '@/services/api';

export const getEntities = async ({ commit }, params) => {
    const response = await axios.get('/event-categories', { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
            let scheme = {}
            data.data["hydra:member"].map(function (item){
               scheme['color'+item.id] = {
                   color: '#fff',
                   backgroundColor: item.color,
               }
            })
            commit('setScheme',  scheme);
        }

    } catch (e) {
        console.log(e);
    }

};
export const getEntitiesNoPaginate = async ({ commit }, params) => {
    const response = await axios.get('/event-categories?paginate=false', { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data["hydra:member"]});
        }

    } catch (e) {
        console.log(e);
    }

};


export const getEntityById = async ({ commit }, id) => {
    commit('setCurrentEntity', null);
    const response = await axios.get(`/event-categories/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {
    return await axios.post('event-categories', payload)
        .then((response) => response)
        .catch((e) => e.response);
};

export const edit = async (_, payload) => {
    return await axios.put('event-categories/' + payload['id'], payload)
        .then((response) => response)
        .catch((e) => e.response);
};

/* export const removeImage = async (_, id) => {
    return await axios.delete('natural_space_images/' + id)
        .then((response) => response)
        .catch((e) => e.response);
}; */

export const deleteEntity= async ({ commit }, id) => {
    await axios.delete(`/event-categories/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};
