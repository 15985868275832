<template>
  <img :width="size" :height="size" class="rounded-circle" v-if="value" :src="imageUrl +value" alt="">
  <img :width="size" :height="size" v-else src="@/assets/img/user.svg" alt="">
</template>

<script setup>
import {defineProps, ref} from "vue";

defineProps({
  value: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    required: false,
    default: 30
  }
})
const imageUrl = ref(process.env.VUE_APP_URL+'/images/')
</script>

<style scoped>

</style>