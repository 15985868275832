<template>

  <div  v-if="currentEntity" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Editar Familiar <i class="small">{{ currentEntity.fullName }}</i>
              </h3>

              <div class="sub-line"></div>
            </div>
            <div class="mt-2">
              <a href="" @click.prevent="exportToPdf()"
                           class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
               Exportar
              </a>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <parent-form :entity="currentEntity" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import ParentForm from "./ParentForm.vue";
import {ElLoading} from "element-plus";

const store = useStore();
const route = useRoute();

const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("parent/getEntityById", id).finally(()=> spin.close());
};

const currentEntity = computed(() => {
  if (!store.state.parent.currentEntity) {
    return null;
  }

  return store.state.parent.currentEntity;

});
onMounted(()=>{
  getCurrentEntity( route.params.id);
});

const exportToPdf = async () => {
  await store.dispatch('parent/exportMyPdf') .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = currentEntity.value.fullName+'.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading PDF:', error);
      });
}

</script>
