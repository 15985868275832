import axios from '@/services/api';

export const getUsers = async ({ commit }, params) => {

    const response = await axios.get('/users', { params: params})

    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getAdmins = async ({ commit }, params) => {

    const response = await axios.get('/administrators', { params: params})

    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getSuperAdmins = async ({ commit }, params) => {

    const response = await axios.get('/super-admins', { params: params})

    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getTotals= async ({ commit }, params) => {

    const response = await axios.get('/totals', { params: params})

    const { data } = response;

    try {
        if (data) {
            commit('setTotals', data);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getMe = async ({ commit }) => {
    await axios.get('/users/me').then(response => {
        const { data } = response;
        if (data) {
            console.log(data)
            commit('setUser', data);
            let role = data.roles
            sessionStorage.setItem('user', JSON.stringify(data))
            if (role.includes('ROLE_SUPER_ADMIN')){
                commit('setUserRole', 'ROLE_SUPER_ADMIN')
                sessionStorage.setItem('userRole', 'ROLE_SUPER_ADMIN');
            } else if(role.includes('ROLE_ADMIN')){
                sessionStorage.setItem('userRole', 'ROLE_ADMIN');
                commit('setUserRole', 'ROLE_ADMIN')
            } else{
                sessionStorage.setItem('userRole', 'ROLE_USER');
                commit('setUserRole', 'ROLE_USER')
                commit('setAccessChildInfo', data['accessChildInfo'])
            }

        }
    })
}

export const getUser = async ({ commit }, id) => {
    await axios.get(`/users/${id}`).then(response => {
        const { data } = response;
        if (data) {
            commit('setUser', data);

        }
    })
}

export const getCurrentUser = async ({ commit }, id) => {
    commit('setCurrentUser', null)
    await axios.get(`/users/${id}`).then(response => {
        const { data } = response;
        if (data) {
            commit('setCurrentUser', data);
        }
    })
}

export const add = async (_, payload) => {
    return await axios.post("users", payload);
};
export const addAdmin = async (_, payload) => {
    return await axios.post("administrators", payload);
};
export const addSuper = async (_, payload) => {
    return await axios.post("super-admins", payload);
};
export const addAdministrator = async (_, payload) => {
    return await axios.post("expedient-administrators", payload);
};

export const edit = async (_, payload) => {
    return await axios.put("users/" + payload['id'], payload);
};


export const config = async (_, payload) => {
    return await axios.post("users/config", payload);
};


export const down = async (_, payload) => {
    return await axios.post("users/down", payload);
};

export const changePassword = async ({ commit }, payload) => {

    commit('setErrorMessage', null)

    return await axios.put("reset-password/" + payload.id + (payload.byAdmin ? "?byAmin=true" : ''),
        payload
    )

};

export const recuperatePassword = async ({ commit }, payload) => {

    commit('setErrorMessage', null)

    return await fetch(process.env.VUE_APP_URL + "/confirm/recuperate-password/" + payload.token , {
        method: 'POST',
        body: JSON.stringify({
            password: payload.password,
            repeatedPassword: payload.repeatedPassword
        })

    });

};

export const deleteUser = async ({ commit }, id) => {

    await axios.delete(`/users/${id}`).then(()=>{
        commit('setLoading', false);
    })
};

export const forgotPassword = async (_, email) => {

    return await fetch(process.env.VUE_APP_URL+"/forgot-password/"+email , {
        method: 'GET'
    });
}

export const exportAdminList = async () => {
    return axios({
        method: "get",
        url: process.env.VUE_APP_URL + "/api/users/export.xlsx",
        responseType: "blob",
    });
}