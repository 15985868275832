<template>
  <form @submit.prevent="handleSubmit" v-if="!store.state.meeting.loading">
    <div class="row">
      <div class="col-md-4">
        <label for="expedient" class="form-control-label w-100"  v-if="isAdmin() || isSuperAdmin()">
          Expediente<span class="required">*</span>
        </label>
        <el-select
            v-if="isAdmin() || isSuperAdmin()"
            :disabled="!expedients"
            v-model="formModel.expedient"
            size="large"
            class="w-100"
        >
          <el-option
              v-for="selectItem in expedients"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.code+ ' ' + selectItem.mainParent.fullName + '--' + selectItem.secondaryParent.fullName"
          />

        </el-select>
        <p v-if="v.expedient.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.expedient.$errors[0].$message }}
          </small>
        </p>
        <template   v-if="isAdmin() || isSuperAdmin()">
          <label for="parentUser" class="form-control-label w-100">
            Familiar<span class="required">*</span>
          </label>
          <el-select
              clearable
              v-model="formModel.parentUser"
              class="w-100"
              placeholder="Familiar"
              size="large">
            <el-option
                v-for="selectItem in parents"
                :key="selectItem.id"
                :value="selectItem['@id']"
                :label="selectItem.fullName"
            />
          </el-select>
        </template>
        <p v-if="v.parentUser.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.parentUser.$errors[0].$message }}
          </small>
        </p>
        <label for="date_time" class="form-control-label" style="margin-right: 5px;margin-top: 5px;display: block;">
          Fecha
        </label>
        <el-date-picker
            v-model="formModel.scheduledAt"
            type="datetime"
            value-format="YYYY-MM-DD H:m"
            placeholder="2023/08/16"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            :dayjs="Dayjs"
            :class="v.scheduledAt.$errors.length >0 ? 'is-invalid' : ''"
        />
        <p v-if="v.scheduledAt.$errors.length >0" class="invalid-feedback  invalid-field mb-0">
          <small>
            {{ v.scheduledAt.$errors[0].$message }}
          </small>
        </p>
      </div>
     <div class="col-md-2">
       <div class="form-group mt-3" v-if="isAdmin() || isSuperAdmin()">
         <div class="col-12  pt-3">
           <el-checkbox v-model="formModel.approved" label="Aprobada" size="large" />
         </div>
         <div class="col-12  pt-3">
           <el-checkbox v-model="formModel.completed" label="Completada" size="large" />
         </div>
         <div class="col-12  pt-3">
           <el-checkbox v-model="formModel.missed" label="Perdida" size="large" />
         </div>
       </div>
     </div>
      <div class="col-md-6">
          <div class="card">
             <div class="card-header">
                 <h3 class="card-title">Solicitudes</h3>
             </div>
            <div class="card-body">
               <ul>
                  <li class="cursor-pointer row" v-for="request in entity.requests" :key="request.id" >
                    <div class="col-md-7">
                      <p>{{ request.reason }}</p>
                    </div>

                    <div class="col-md-3 text-center">
                      <el-switch
                          @change="approveRequest(request.id)"
                          v-model="request.approved"
                          size="large"
                          active-text=" Aprobado"
                          inactive-text=""
                      />
                    </div>
                    <div class="col-md-2 text-center">
                      <a
                          class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                          href=""
                          @click.prevent="showRequest(request)"
                      >
                        <el-icon :size="25" color="FFF">
                          <View />
                        </el-icon>
                      </a>
                    </div>

                  </li>
               </ul>
              <el-dialog size="30%" v-model="showRequestDetails" title="Detalles de solicitud">
                 <RequestDetails :request="selectedItem" />
              </el-dialog>
            </div>
          </div>
      </div>
      <div class="mt-3">
        <button
            type="submit"
            class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
          {{ buttonText }}
        </button>

        <button
            class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
            @click.prevent="handleCancel"
        >
          Cancelar
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import 'dayjs/locale/en';
import {isAdmin, isSuperAdmin} from "@/services/authGuard";
import {View} from "@element-plus/icons-vue";
import RequestDetails from "@/views/meeting/RequestDetails.vue";
import {clearErrors} from "@/utils/errorsUtils";


const store = useStore();
const router = useRouter();
const expedients = computed(() => store.state.expedient.entities.data);
const showRequestDetails = ref(false)
const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});
const expedient = ref(null)
const selectedItem = ref(null)
const parents = computed(() => {
  if(expedient.value){
    return [expedient.value.mainParent, expedient.value.secondaryParent]
  }
  return store.state.parent.entities.data
});

const formModel = ref(props.entity|| {});

onMounted(async () => {
  await store.dispatch("meeting/setLoading", true);
  await store.dispatch("parent/getEntitiesNoPaginate");
  await store.dispatch("expedient/getEntitiesNoPaginate");
  if (props.entity) {
    formModel.value.expedient = props.entity.expedient ? props.entity.expedient['@id'] : null
    formModel.value.parentUser = props.entity.parentUser ? props.entity.parentUser['@id'] : null
  }
  await store.dispatch("meeting/setLoading", false);
})
const showRequest = (item) => {
  selectedItem.value = item
   showRequestDetails.value = !showRequestDetails.value

}
const approveRequest = async (id) => {
  await store.dispatch("request/approved", id).then(async (res) => {
    if (res.status && (res.status === 200 || res.status === 201)) {
      console.log('Ok')
    } else {
      console.log('Error')
    }
  })
}
const rules = computed(() => {
  return {
    scheduledAt: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    parentUser: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    administrator: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
    () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.name = null
  formModel.value.province = null
  formModel.value.image = null
};


const handleCancel = () => {
  router.push({ name: 'Meetings', params: {} });
};

const updateEntity = async (data) => {
    await store.dispatch("meeting/edit", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Reunión editada satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });

    clearForm()
    await router.push({ name: 'Meetings', params: {} });

};

const createEntity = async (data) => {
    await store.dispatch("meeting/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Reunión creada satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });

    await router.push({ name: 'Meetings', params: {} });
};

</script>
