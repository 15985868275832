<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Profesionales
                </h3>

                <div class="sub-line"></div>
              </div>
              <div class="text-right flex flex-row gap-4 mt-2">
                <router-link :to="{name: 'Crear-Admin'}"
                   class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                  Nuevo Usuario
                </router-link>

                <a href=""  @click.prevent="exportToExcel()"
                   class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                  Exportar
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 py-2">
                <el-input
                  clearable
                  type="text"
                  placeholder="Nombre"
                  v-model="searchName"
                  v-on:keyup.enter="handleSearch"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click="handleSearch"
                  class="float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border
                      hover:bg-blue-900 border-1
                      mt-2
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase font-weight-bolder">
                      Nombre
                    </th>
                    <th class="text-center text-uppercase font-weight-bolder">
                      <div class="d-none d-lg-table-cell">Email</div>
                      <div class="d-lg-none d-table-cell">Datos</div>
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in users" :key="item.id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <Avatar :value="item.photoPath" />
                            {{ item.fullName }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-block d-lg-none">
                        <router-link
                            :to="{
                          name: 'Actualizar-Usuario',
                          params: { id: item['id'] },
                        }"
                        >
                          <a
                              class="btn btn-link text-dark px-3 mb-0"
                              href="javascript:;"
                          >
                            <i
                                class="fa fa-pencil text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >Editar
                          </a>
                        </router-link>
                        <router-link
                            :to="{
                          name: 'Cambiar-Contraseña-Usuario',
                          params: { id: item['id'] },
                        }"
                        >
                          <a
                              class="btn btn-link text-dark px-3 mb-0"
                              href="javascript:;"
                          >
                            <i
                                class="fa fa-key text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >Cambiar Contraseña
                          </a>
                        </router-link>
                        <a
                            class="btn btn-link text-danger text-gradient px-3 mb-0"
                            href="javascript:;"
                            @click="handleDelete(item['id'])"
                        >
                          <i class="far fa-trash-alt me-2" aria-hidden="true"></i
                          >Borrar
                        </a>
                      </div>
                    </td>
                    <td style="padding-left: 40px;">
                      <div class="d-lg-table-cell d-none"> {{ item.email }}</div>
                      <div class="ml-3 d-lg-none d-table-block"><b>Email: </b><br>{{ item.email }}</div>
                    </td>
                    <td class="d-none d-lg-table-cell text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Usuario',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link text-dark px-3 mb-0"
                          href="javascript:;"
                        >
                          <i
                            class="fa fa-pencil text-dark me-2"
                            aria-hidden="true"
                          ></i
                          >Editar
                        </a>
                      </router-link>
                      <router-link
                          :to="{
                          name: 'Cambiar-Contraseña-Usuario',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                            class="btn btn-link text-dark px-3 mb-0"
                            href="javascript:;"
                        >
                          <i
                              class="fa fa-key text-dark me-2"
                              aria-hidden="true"
                          ></i
                          >Cambiar Contraseña
                        </a>
                      </router-link>
                      <a
                        class="btn btn-link text-danger text-gradient px-3 mb-0"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination :pagination="store.state.user.pagination" entity="user" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import Avatar from "@/components/Avatar.vue";
import Pagination from "@/components/Pagination.vue";

const store = useStore();
const searchName = ref("");

    onMounted(() => {
      getUsers();
    });


    const handleSearch = () => {
      store.dispatch("user/getAdmins", {
        firstName: searchName.value
      });
    };

const users = computed(() => store.state.user.entities.data);

  const getUsers = async () => {
    const spin = ElLoading.service({
      lock: true,
      text: '',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    await store.dispatch("user/getAdmins").then(()=> {
      spin.close()
    });
}

const deleteUser = async (user) => {
  try {
    await store.dispatch("user/deleteUser", user);
    await getUsers()
  } catch (error) {
    console.log(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Estás seguro que deseas eliminar el usuario?")
    .then(() => {
      deleteUser(item);
    })
    .catch(() => {
      // catch error
    });
};

const exportToExcel = async () => {
  await store.dispatch('user/exportAdminList') .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = 'administradores.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading Excel:', error);
      });
}

</script>
