<template>
   <div class="card" v-if="hasExpedients">
     <div class="card-header">
       <div class="row">
         <div class="col-md-6">
           <h3 class="card-title mb-0 d-inline-block">
             Próximos eventos
           </h3>
         </div>
         <div class="col-md-6 text-end">
           <button @click="toggleView" class="btn btn-primary btn-sm border-1 text-white mb-0" style="margin-right: 15px;">ver como {{ viewText }}</button>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-md-3 col-12 py-2 search-field">
         <el-date-picker
             v-model="searchDate"
             type="date"
             value-format="YYYY-MM-DD"
             placeholder="2023/08/16"
             :disabled-date="disabledDate"
             :shortcuts="shortcuts"
             :dayjs="Dayjs"
         />
       </div>
     </div>
     <div class="card-body">
       <Qalendar v-if="entities && view === 'calendario'"
                 :events="entities"
                 :config="config"
       />
       <div v-if="view === 'tabla'" class="table-responsive p-0">
           <table class="table align-items-center mb-0">
             <tbody>
             <tr v-for="item in entities" :key="item.id">
               <td>
                 <div class="d-flex">
                   <div class="d-flex flex-column">
                     <h6 class="mb-0 text-sm wrap-balance">
                       <router-link
                           :to="{
                                  name: 'Detalle-Event',
                                  params: { id: item.id },
                                }"
                       >
                         <div :style="{backgroundColor: item.color}" class="event-color"></div>
                         {{ item.title }} ({{item.category.name }})
                       </router-link>
                     </h6>
                   </div>
                 </div>
               </td>
               <td class="text-center">
                 <router-link
                     class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                     :to="{
                            name: 'Detalle-Event',
                            params: { id: item.id },
                          }"
                 >  <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                   <View />
                 </el-icon>
                 </router-link>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
   </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { ref, watch } from 'vue'
const store = useStore();
const searchDate = ref(new Date());
import {View} from "@element-plus/icons-vue";
import 'dayjs/locale/en';
import { Qalendar } from "qalendar";

const getEvents = async () => {
  return await store.dispatch("parentEvent/getEntities", {
    "parentUser.id": store.getters['user/getUser'].id,
   // dateTime: searchDate.value,
  });
};

onMounted(()=> {
  getEvents();
});
const view = ref("calendario")
const viewText = computed(() => {
  if(view.value === 'calendario'){
    return 'tabla'
  }else{
    return 'calendario'
  }
});

const entities = computed(
    () => store.state.parentEvent.entities.data
);
const hasExpedients = computed(
    () => store.state.expedient.entities.data ? store.state.expedient.entities.data.filter(item => {
      return !item.banned || !item.banned.includes(store.getters['user/getUser'].id)
    }) >0 : false
)
const handleSearch = () => {
  getEvents();
};
watch(searchDate, async () => {
   handleSearch()
})
const colorScheme = computed(() => store.state.eventCategory.scheme);
const  config = computed(() => {
  return {
    locale: "es-ES",
    defaultMode: "month",
    style: {
      colorSchemes: colorScheme
    }
  }
});
const toggleView = () => {
  if(view.value === 'calendario'){
    view.value = 'tabla'
  }else{
    view.value = 'calendario'
  }
}
</script>

<style scoped lang="scss">
 .search-field {
   @media (min-width: 992px) {
     margin-left: 25px;
   }
   margin-left: 10px;
 }
 .event-color {
   width: 15px;
   height: 15px;
   border-radius: 50%;
   display: inline-block;
   margin-top: 3px;
 }
</style>