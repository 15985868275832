<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="user_firstname" class="form-control-label">
          Nombre<span class="required">*</span>
        </label>
        <input
            id="user_firstname"
            v-model="formModel.firstName"
            :class="[
                        'form-control',
                        v.firstName.$error ? 'is-invalid' : '',
                      ]"
            type="text"
            placeholder=""
        />
        <p v-if="v.firstName.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.firstName.$errors[0].$message }}
          </small>
        </p>
        <label for="user_lastname" class="form-control-label">
          Apellidos<span class="required">*</span>
        </label>
        <input
            v-model="formModel.lastName"
            id="user_lastname"
            :class="[
                        'form-control',
                        v.lastName.$error ? 'is-invalid' : '',
                      ]"
            type="text"
            placeholder=""
        />
        <p v-if="v.lastName.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.lastName.$errors[0].$message }}
          </small>
        </p>
        <label for="user_email" class="form-control-label">
          Email<span class="required">*</span>
        </label>
        <input
            id="user_email"
            v-model="formModel.email"
            :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
            type="email"
            placeholder=""
        />
        <p v-if="v.email.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.email.$errors[0].$message }}
          </small>
        </p>
        <label for="user_active" class="form-control-label w-100">
          Activo
        </label>
        <el-select
            v-model="formModel.enabled"
            size="large"
            class="w-100"
        >
          <el-option :value="true" label="Si" />
          <el-option :value="false" label="No"/>
        </el-select>
      </div>
      <div class="col-md-6">
        <div v-if="action=== 'create'">
          <label for="user_password" class="form-control-label">
            Contraseña<span class="required">*</span>
          </label>
          <input
              v-model="formModel.password"
              id="user_password"
              :class="[
                        'form-control',
                        v.password.$error ? 'is-invalid' : '',
                      ]"
              type="password"
              placeholder="Entre una contraseña"
          />
          <p v-if="v.password.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.password.$errors[0].$message }}
            </small>
          </p>
          <label for="user_password_second" class="form-control-label">
            Repetir Contraseña<span class="required">*</span>
          </label>
          <input
              v-model="formModel.retypePassword"
              id="user_password_second"
              :class="['form-control',   v.retypePassword.$error ? 'is-invalid' : '']"
              type="password"
              placeholder="Repita la Contraseña"
          />
          <p v-if="v.retypePassword.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.retypePassword.$errors[0].$message }}
            </small>
          </p>
          <p v-if="repeatPasswordError" class="invalid-feedback mb-0">
            <small>
              <span v-if="repeatPasswordError"> {{ repeatPasswordError }}</span>
            </small>
          </p>
        </div>
      </div>
    </div>


    <div class="mt-3">
      <button
          type="submit"
          class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
      >
        {{ buttonText }}
      </button>
      <button
          class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
          @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
  </form>

</template>

<script setup>
import {ref, computed, defineProps, onMounted} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import {required, helpers, email, minLength} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {clearErrors} from "@/utils/errorsUtils";

const store = useStore();
const router = useRouter();
const admin = ref(router.currentRoute.name === 'Crear-Admin' || router.currentRoute.name === 'Actualizar-Admin')

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },
  action: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: false,
    default:'admin'
  }
})

const buttonText = computed(() => props.action === 'create' ? 'Crear' : 'Guardar cambios');

const repeatPasswordError = ref()
const formModel = ref(props.entity|| {});

onMounted(()=>{
  formModel.value.roles = admin.value ? ["ROLE_USER","USER_ADMIN"] : ["ROLE_USER","ROLE_SUPER_ADMIN"]
  formModel.value.type = admin.value ? 'administrador' : 'superadmin'
});
const rules = computed(() => {
  let data =  {
    firstName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    email: {
      required: helpers.withMessage("Este campo es obligatorio", required),
      email: helpers.withMessage("Debe ser una dirección de email válida", email),
    },
    roles: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    enabled: {}
  };
  if(props.action === 'create'){
    data.password = {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
    data.retypePassword =  {
      minLength: helpers.withMessage("Debe tener al menos 6 caracteres", minLength(6)),
      required:  helpers.withMessage("Este campo es obligatorio", required),
    }
  }
  return data
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
v.locale = 'es'
const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    repeatPasswordError.value = null
    if(props.action === 'create'){
      if(formModel.value.password !== formModel.value.retypePassword ){
        repeatPasswordError.value = 'Las contraseñas no coinciden'
      }else{
        createEntity(formModel.value);
      }
    }else{
      updateEntity(formModel.value);
    }
  }
};
const handleCancel = () => {
  router.push({ name: props.type === 'admin' ? "Admins" : "SuperAdmins", params: {} });
};

const createEntity = async (user) => {
  await store.dispatch(props.type === 'admin' ? 'user/addAdmin' : "user/addSuper", user).then(async (res) => {
    if (res.status === 200 || res.status === 201) {
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Usuario creado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
      await router.push({name: props.type === 'admin' ? "Admins" : "SuperAdmins", params: {}});
    } else {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: clearErrors(res),
        showConfirmButton: false,
        timer: 3000
      })
    }

  })
};

const updateEntity = async (user) => {

  await store.dispatch("user/edit", user).then(async (res) => {
    if (res.status === 200) {
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Usuario editado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
      await router.push({name: props.type === 'admin' ? "Admins" : "SuperAdmins", params: {}});
    } else {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: res.response.data['hydra:description'],
        showConfirmButton: false,
        timer: 3000
      })
    }
  })

};


</script>
