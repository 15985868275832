<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="expedient" class="form-control-label w-100">
          Expediente<span class="required">*</span>
          <span class="mb-0 text-black d-inline-block w-auto">
            <small>
              (Aparecen solo los expedientes que aun no tengan acuerdo creado)
            </small>
          </span>
        </label>
        <el-select
            v-model="formModel.expedient"
            size="large"
            class="w-100"
        >
          <el-option
              v-for="selectItem in expedients"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.code + ' '+ selectItem.mainParent.fullName + '--' + selectItem.secondaryParent.fullName"
          />
        </el-select>
        <p v-if="v.expedient.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.expedient.$errors[0].$message }}
          </small>
        </p>
        <label for="parent_user" class="form-control-label w-100">
          Familiar<span class="required">*</span>
        </label>
        <el-select
            :disabled="isParent()"
            v-model="formModel.authorParent"
            size="large"
            class="w-100"
        >
          <el-option
              v-for="selectItem in parents"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.fullName"
          />
        </el-select>
        <p v-if="v.authorParent.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.authorParent.$errors[0].$message }}
          </small>
        </p>
          <label for="expedient" class="form-control-label w-100">
            Documento<span class="required">*</span>
          </label>
          <el-select
              :disabled="documents.length === 0"
              v-model="formModel.document"
              size="large"
              class="w-100"
          >
            <el-option
                v-for="selectItem in documents"
                :key="selectItem.id"
                :value="selectItem['@id']"
                :label="selectItem.title"
            />
          </el-select>
          <p v-if="v.document.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.document.$errors[0].$message }}
            </small>
          </p>
        <template  v-if="documents && documents.length === 0 && (formModel && formModel.expedient)">
          <p class="invalid-feedback mb-0">
            <small>
              No tiene actualmente documentos de tipo Acuerdos asociados a este expediente
            </small>
          </p>
          <p>
            <router-link class="btn btn-sm btn-primary" :to="{name:'Crear-Document'}">Subir documento</router-link>
          </p>
        </template>

    </div>
      <div class="col-md-6">
          <label for="endorsedByAuthor" class="form-control-label w-100">
            Respaldado por autor
          </label><br>
          <el-switch :disabled="isParent() && props.entity && props.entity.authorParent !== formModel.authorParent" id="endorsedByAuthor" v-model="formModel.endorsedByAuthor" />
        <label for="endorsedByOtherParent" class="form-control-label w-100">
          Respaldado por familiar
        </label><br>
        <el-switch id="endorsedByOtherParent" v-model="formModel.endorsedByOtherParent"
                   :disabled="isParent() && props.entity && props.entity.authorParent === formModel.authorParent" />
      </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {computed, defineProps, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {isParent} from "@/services/authGuard";
import {clearErrors} from "@/utils/errorsUtils";
import {ElLoading} from "element-plus";

const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {});

store.dispatch("parent/getEntities");
store.dispatch("expedient/getEntities");

const rules = computed(() => {
  return {
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },

    authorParent: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },

    document: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);
const parents = computed(() => {
  if(formModel.value.expedient && store.state.expedient.currentEntity){
    return [
      store.state.expedient.currentEntity.mainParent,
      store.state.expedient.currentEntity.secondaryParent
    ]
  }
 return  []
});

const expedients = computed(() => {
  if( !store.state.expedient.entities.data) return []
  if(props.action === 'create'){
    return store.state.expedient.entities.data.filter(item => {
      return item.agreements.length === 0
    })
  }else{
    return store.state.expedient.entities.data
  }

});

const documents = computed(() => {
  if( !formModel.value || !formModel.value.expedient) return null
  return store.state.expedient.currentEntity ? store.state.expedient.currentEntity.documents.filter(item => {
   return item.category.name === 'Acuerdos'
  }) : []
});

onMounted(async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("parent/getEntities");
  await store.dispatch("expedient/getEntities").then(async () => {
    if (props.entity && props.entity.expedient && props.entity.expedient.id) {
      let filtered = store.state.expedient.entities.data.filter(item => {
        return item.id === props.entity.expedient.id
      })
      if (filtered.length > 0) {
        await store.dispatch('expedient/getEntityById', filtered[0].id).then(() => {
          formModel.value.expedient = store.state.expedient.currentEntity
        })
      }
    }

  });

  await store.dispatch("document/getEntities");
  if (isParent()) {
    formModel.value.authorParent = store.getters['user/getUser']['@id']
  }

  if (props.entity && props.entity.document) {
    formModel.value.document = props.entity.document['@id']
    await store.dispatch('expedient/getEntityById', props.entity.expedient.id)
  }
  spin.close()
});

watch(() => formModel.value.expedient, async (val) => {
  if (!val) {
    return
  }
  if(store.state.expedient.currentEntity && store.state.expedient.currentEntity['@id'] === formModel.value.expedient){
    return
  }
  let filtered = expedients.value.filter(item => {
    if (val['@id']) {
      return item['@id'] === val['@id']
    }
    return item['@id'] === val
  })
  if(filtered.length >0){
    await store.dispatch('expedient/getEntityById',filtered[0].id)
  }


})

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value = {
    expedient: null,
    document: null,
    authorParent:null
  }
  v.$reset()
};

const handleCancel = () => {
  router.push({ name: 'Agreements', params: {} });
};


const updateEntity = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  if(formModel.value.expedient && formModel.value.expedient.id){
    formModel.value.expedient = formModel.value.expedient['@id']
  }
  if(formModel.value.authorParent && formModel.value.authorParent.id){
    formModel.value.authorParent = formModel.value.authorParent['@id']
  }
    await store.dispatch("agreement/edit", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await router.push({name: 'Agreements', params: {}});
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Acuerdo editado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
      clearForm()

    }else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
  }
})
}

const createEntity = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })

  if(formModel.value.authorParent.id){
    formModel.value.authorParent = formModel.value.authorParent['@id']
  }
    await store.dispatch("agreement/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await router.push({name: 'Agreements', params: {}});
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Acuerdo creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        clearForm()
      } else {
        spin.close()
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });

}

</script>
