<template>
  <el-config-provider :locale="locale">
  <router-view />
  </el-config-provider>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { ElConfigProvider } from 'element-plus';
import es from 'element-plus/dist/locale/es.mjs';
import 'dayjs/locale/es.js';
import Swal from "sweetalert2";

const store = useStore();
const locale = ref(es);

const url = new URL(process.env.VUE_APP_MERCURE_URL);
if(store.getters["user/getUser"]){
  url.searchParams.append('topic', '/'+store.getters["user/getUser"].id);
}

const mes = new EventSource(url);
mes.onmessage = (e) => {
  const data = JSON.parse( e.data);
  Swal.fire({
    position: 'top-end',
    title: data.title,
    text: data.text,
    icon: null,
    showConfirmButton: false,
  })
}

onBeforeMount(() => {
  store.dispatch("auth/initializeStore");
});
</script>
