<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="py-4 container-fluid" v-if="formModel">
              <div class="row">
                <div class="col-12">
                  <div class="card mb-4 mt-5 dd">
                    <div class="card-header pb-0 d-flex justify-content-between">
                      <h6>Cambiar contraseña</h6>
                    </div>
                    <div class="card-body">
                      <div class="col-sm mx-60">
                        <form @submit.prevent="handleSubmit">
                          <div class="row">
                            <div class="row">
                              <div class="col-md-12">
                                <label for="password" class="form-control-label">
                                  Contraseña nueva
                                </label>
                                <input
                                    v-model="formModel.password"
                                    id="password"
                                    class="form-control"
                                    type="password"
                                    placeholder=""
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <label for="repeatedPassword" class="form-control-label">
                                  Repetir Contraseña nueva
                                </label>
                                <input
                                    v-model="formModel.repeatedPassword"
                                    id="repeatedPassword"
                                    class="form-control"
                                    type="password"
                                    placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <button
                                type="submit"
                                class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                            >
                              Cambiar contraseña
                            </button>
                            <button
                                class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                                @click="handleCancel"
                            >
                              Cancelar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import Swal from "sweetalert2";


    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const token = ref()
    const formModel = ref()

    onMounted(()=>{
       token.value = route.params.token
       formModel.value = {
        password: null,
        repeatedPassword: null,
        token: token.value
      }
    })
    const errorMessage = computed(() => store.getters["user/getErrorMessage"]);

    const handleSubmit = () => {

        if(formModel.value.repeatedPassword !== formModel.value.password){
            ElMessage.error("Las contraseñas no coinciden");
          return;
        }

      changePassword(formModel.value);
    };

    const changePassword = async (data) => {
      try {
        await store.dispatch("user/recuperatePassword", data).then(async (res) => {
          if (res.status === 200) {
            await Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Su contraseña ha sido cambiada satisfactoriamente',
              showConfirmButton: false,
              timer: 5000
            })
          } else {
            let json = await res.json()
            await Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: json.message,
              showConfirmButton: false,
              timer: 5000
            })
          }
        }).catch((res)=>{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 5000
          })
        })
        if (errorMessage.value === null) {
          await router.push({ name: "Dashboard" });
        }else{
            ElMessage.error(errorMessage.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Login" });
    };

</script>
<style lang="scss">
.dd{
  margin: auto;
  width: 50%;
  @media(max-width: 700px){
    width: 100%;
  }
}
</style>