<template>
  <div class="card">
    <div class="card-header">
      <h3 class="title flex flex-row justify-between mb-0">
        Profesionales
         <button v-if="isAdmin()" @click="showForm = true" class="btn btn-primary btn-sm border-1 text-white mb-0">Crear nuevo</button>
      </h3>

    </div>
    <div class="card-body">
      <div v-for="admin in administrators" :key="admin.id">
        <p> <img alt="child"  width="25"  src="@/assets/img/user.svg"> {{ admin.fullName }} </p>
      </div>
    </div>
  </div>
  <el-dialog class="dd" v-model="showForm" title="Crear Administrador">
    <AdministratorForm :expedient="expedient"
                       action="create"
                       @close-form="closeParentForm"
                       @update-expedient="updateExpedient"
    />
  </el-dialog>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {isAdmin} from "@/services/authGuard";
import AdministratorForm from "@/views/expedient/components/AdministratorForm.vue";


const showForm = ref(false)
 defineProps({
  expedient: {
    type: Object,
    required: true
  },
  administrators: {
    type: Array,
    required: false
  }
});


const closeParentForm = () =>{
  showForm.value = false
}

const updateExpedient = async () => {


}
</script>

<style scoped>

</style>