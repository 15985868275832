
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/scss/custom.scss";
import "./assets/scss/customElement.scss";
import Theme from "./theme";
import ElementPlus from 'element-plus'
import es from 'element-plus/dist/locale/es.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const appInstance = createApp(App);

appInstance.use(store);
appInstance.use(router);
appInstance.use(Theme);
appInstance.use(ElementPlus, {
    locale: es,
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    appInstance.component(key, component)
}

appInstance.mount("#app");
