<template>
  <div class="row mt-3">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            Expedientes
          </h3>
        </div>
        <div class="card-body">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th
                    class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                >
                  Expediente
                </th>
                <td class="text-center"></td>
              </tr>
              </thead>

              <tbody>
              <tr @click="selectExpedient(item)" v-for="item in entities" :key="item.id" :class="selectedExpedient && (item.id === selectedExpedient.id) ? 'bg-primary color-white' : ''">
                <td style="padding-left: 0!important;">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        <img v-if="!selectedExpedient" src="@/assets/img/expedients-black.svg" alt="">
                        <img v-else src="@/assets/img/expedients.svg" alt=""> 
                        {{ item.code }} -- {{ item.mainParent.fullName }} / {{ item.secondaryParent.fullName }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <button
                      class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                  >  <el-icon :size="25"
                              class="mr-2"
                              color="#fff">
                    <View />
                  </el-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <expedients-events v-if="selectedExpedient" :current-entity="selectedExpedient" :events="events" @update-entity="getCurrentExpedient"/>
    </div>
  </div>

</template>

<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import { useStore } from "vuex";
import { View} from "@element-plus/icons-vue";
import ExpedientsEvents from "@/views/event/ExpedientsEvents.vue";
import {isParent} from "@/services/authGuard";
import {ElLoading} from "element-plus";


const store = useStore();
/*const searchName = ref("");

const handleSearch = () => {
  store.dispatch("event/getEntities", {
    name: searchName.value
  });
};*/
defineProps({
  hasTitle: {
    type: Boolean,
    required: false,
    default: true
  }
})
const selectedExpedient = ref()

const getExpedients = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
   await store.dispatch("expedient/getEntities");
  spin.close()
};
const getCurrentExpedient = async () => {
  if(selectedExpedient.value) {
    await store.dispatch('expedient/setLoading', true)
    await store.dispatch('expedient/getEntityById', selectedExpedient.value.id).then(async () => {
      await store.dispatch('parentEvent/getEntities', {
        'expedient.id': store.state.expedient.currentEntity.id
      })
      await store.dispatch('administratorEvent/getEntities', {
        'expedient.id': store.state.expedient.currentEntity.id
      })
    })
    await store.dispatch('expedient/setLoading', false)
  }
}

onMounted(()=> {
  getExpedients();
});

const selectExpedient = async item => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  selectedExpedient.value = item
  await store.dispatch('expedient/setLoading', true)
  await store.dispatch('expedient/getEntityById', item.id).then(async () => {
    await store.dispatch('parentEvent/getEntities', {
      'expedient.id': store.state.expedient.currentEntity.id
    })
    await store.dispatch('administratorEvent/getEntities', {
      'expedient.id': store.state.expedient.currentEntity.id
    })
    await store.dispatch('expedient/setLoading', false)
  })
  spin.close()
}
const events = computed(
    () => {
      let result = []
      if(store.state.parentEvent.entities.data){
        store.state.parentEvent.entities.data.map(item=> {
          result.push(item)
        })
      }
      if(store.state.administratorEvent.entities.data) {
        store.state.administratorEvent.entities.data.map(item => {
          if(isParent()){
            item.isEditable = false
          }

          result.push(item)
        })
      }
      return result;
    }
);
const entities = computed(
    () => store.state.expedient.entities.data
);


</script>

<style scoped lang="scss">
.color-white {
  color: white!important;
  a, i, h6 {
    color: white!important;
    cursor: pointer!important;
  }
}
</style>