<template>
  <div class="row mt-3">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            Expedientes
          </h3>
        </div>
     <div class="card-body">
       <div class="row">
         <div class="col-md-6 col-12 py-2">
           <el-input
               clearable
               type="text"
               placeholder="Buscar"
               v-model="searchName"
           />
         </div>
       </div>
         <div class="table-responsive p-0">
           <table class="table align-items-center mb-0">
             <thead>
             <tr>
               <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
                 Expediente
               </th>
               <th class="text-uppercase text-custom text-xxs text-center font-weight-bolder opacity-7">
               </th>
             </tr>
             </thead>

             <tbody>
             <tr  @click="selectExpedient(item)" v-for="item in entities" :key="item.id"  :class="selectedExpedient && (item.id === selectedExpedient.id) ? 'bg-primary color-white' : ''" class="cursor-pointer">
               <td style="padding-left: 0!important;">
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex flex-column justify-content-center">
                     <h6 class="mb-0 text-sm wrap-balance">
                       <img v-if="!selectedExpedient" src="@/assets/img/expedients-black.svg" alt="">
                       <img v-else src="@/assets/img/expedients.svg" alt="">
                       {{ item.expedient.code }} -- {{ item.expedient.mainParent.fullName }} / {{ item.expedient.secondaryParent.fullName }}
                     </h6>
                   </div>
                 </div>
               </td>
               <td class="text-center">
                 <button
                     class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                 > <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                   <View />
                 </el-icon>
                 </button>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
   </div>
    </div>
    <div class="col-md-7">
      <expedients-events v-if="selectedExpedient" :current-entity="selectedExpedient?.expedient" :events="events" @update-entity="getCurrentExpedient"/>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted,defineProps } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import {View} from "@element-plus/icons-vue";
import ExpedientsEvents from "@/views/event/ExpedientsEvents.vue";



const store = useStore();
const searchName = ref("");
const selectedExpedient = ref()

defineProps({
     hasTitle: {
        type: Boolean,
        required: false,
        default: true
     }
})

/*watch(searchName, async () => {
  handleSearch()
})*/

const getCurrentExpedient = async () => {
  if(selectedExpedient.value) {
    await store.dispatch('administratorExpedient/setLoading', true)
    await store.dispatch('administratorExpedient/getEntityById', selectedExpedient.value.id)
    await store.dispatch('administratorExpedient/setLoading', false)
  }
}
const getExpedients = async () => {
  return await store.dispatch("administratorExpedient/getEntities", {
  });
};

onMounted(()=> {
  getExpedients();
});

const events = computed(
    () => store.state.administratorExpedient.currentEntity ? store.state.administratorExpedient.currentEntity.expedient.events : []
);
const entities = computed(
    () => store.state.administratorExpedient.entities.data
);
const selectExpedient = async item => {
  selectedExpedient.value = item
  await store.dispatch('administratorExpedient/setLoading', true)
  await store.dispatch('administratorExpedient/getEntityById', item.id)
  await store.dispatch('administratorExpedient/setLoading', false)
}
</script>

<style scoped lang="scss">
   .color-white {
     color: white!important;
     cursor: pointer!important;
      a, i, h6 {
        color: white!important;
      }
   }
</style>