<template>
  <div class="row mt-3">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            Expedientes
          </h3>
        </div>
     <div class="card-body">
       <div class="row">
         <div class="col-md-6 col-12 py-2">
           <el-input
               clearable
               type="text"
               placeholder="Buscar"
               v-model="searchName"
           />
         </div>
       </div>
         <div class="table-responsive p-0">
           <table class="table align-items-center mb-0">
             <thead>
             <tr>
               <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
                 Expediente
               </th>
               <th class="text-uppercase text-custom text-xxs text-center font-weight-bolder opacity-7">
                 Detalles
               </th>
               <td class="d-none d-lg-table-cell text-center"></td>
             </tr>
             </thead>

             <tbody>
             <tr  @click="selectedExpedient = item" v-for="item in entities" :key="item.id"  :class="selectedExpedient && (item.id === selectedExpedient.id) ? 'bg-primary color-white' : ''" class="cursor-pointer">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex flex-column justify-content-center">
                     <h6 class="mb-0 text-sm wrap-balance">
                         {{ item.expedient.code }} -- {{ item.expedient.mainParent.fullName }} / {{ item.expedient.secondaryParent.fullName }}
                     </h6>
                   </div>
                 </div>
               </td>
               <td class="text-center">
                 <button
                     class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                 > <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                   <View />
                 </el-icon>
                 </button>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
   </div>
    </div>
    <div class="col-md-7">
      <div class="card">
        <div class="card-header">
          <div class="row">
             <div class="col-md-6">
               <h3 class="card-title mb-0 d-inline-block">
                 Solicitudes de Reuniones
                 <span v-if="!selectedExpedient" class="text-sm text-danger">( Seleccione un expediente)</span>
                 <span class="text-sm" v-else><br>Expediente:  {{ selectedExpedient.expedient.code }}</span>
               </h3>
             </div>
            <div class="col-md-6" style="text-align: right;padding-right: 50px;}">
              <h5 class="text-white text-sm mr-3" @click="getAllEvents()">Ver todos</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive p-0" v-if="selectedExpedient">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th class="d-table-cell d-lg-none text-uppercase font-weight-bolder">
                  Datos
                </th>
                <th class="d-lg-table-cell d-none text-uppercase font-weight-bolder">
                  Familiar
                </th>
                <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                  Profesional
                </th>
                <th class="d-none d-lg-table-cell text-uppercase font-weight-bolder">
                  Fecha
                </th>
                <td class="d-none d-lg-table-cell text-center"></td>
              </tr>
              </thead>

              <tbody>
              <tr v-for="item in selectedExpedient.expedient.meetingRequests" :key="item.id">
                <td class="d-none d-lg-table-cell">
                  {{ item.parentUser.fullName }}
                </td>
                <td class="d-none d-lg-table-cell">
                  {{ item.administrator.fullName }}
                </td>
                <td class="d-none d-lg-table-cell">
                  {{ formatDate(item.scheduledAt) }}
                </td>
                <td class="d-none d-lg-table-cell text-center">
                  <router-link
                      class="btn btn-link text-dark mb-0 rounded icon-primary"
                      :to="{
                            name: 'Actualizar-Meeting',
                            params: { id: item.id },
                          }"
                  > <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                    <Edit />
                  </el-icon>Editar
                  </router-link>
                  <a
                      class="btn btn-link text-danger text-gradient mb-0 rounded icon-danger"
                      href="javascript:;"
                      @click="handleDelete(item.id)"
                  >
                    <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                      <Delete />
                    </el-icon>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted,defineProps } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import { ref } from "vue";
import {Delete, Edit, View} from "@element-plus/icons-vue";
import moment from "moment";



const store = useStore();
const searchName = ref("");
const selectedExpedient = ref()

defineProps({
     hasTitle: {
        type: Boolean,
        required: false,
        default: true
     }
})

/*watch(searchName, async () => {
  handleSearch()
})*/

const getAllEvents = () => {

}
const getExpedients = async () => {
  return await store.dispatch("administratorExpedient/getEntities", {
    "administrator.id": store.getters['user/getUser'].id
  });
};

onMounted(()=> {
  getExpedients();
});

const entities = computed(
    () => store.state.administratorExpedient.entities.data
);
const deleteEntity = async (position) => {
  try {
    await store.dispatch("meeting/deleteEntity", position);
    await getExpedients();
  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Expediente?")
      .then(() => {
        deleteEntity(item);
      })
      .catch((error) => {
        console.error(error);
      });
};
const formatDate = (value) => {
  if (value) {
    return moment((value)).format('MM/DD/YYYY HH:mm')
  }

  return ''
};

</script>

<style scoped lang="scss">
   .color-white {
     color: white!important;
     cursor: pointer!important;
      a, i, h6 {
        color: white!important;
      }
   }
</style>