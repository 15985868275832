<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Documentos
                </h3>

                <div class="sub-line"></div>
              </div>
              <div class="text-right flex flex-row gap-4 mt-2">
                <router-link :to="{ name: 'Crear-Document' }"
                             class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                  Nuevo Documento
                </router-link>

                <a href=""  v-if="isTotal()" @click.prevent="exportToExcel()"
                   class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                  Exportar
                </a>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-3 col-12 py-2">
                <el-input
                    clearable
                    type="text"
                    placeholder="Título"
                    v-model="searchTitle"
                />
              </div>
              <div class="col-md-3 col-12">
                <el-select  clearable v-model="searchExpedient" class="mt-2 w-100" placeholder="Expediente" size="large">
                  <el-option
                      v-for="selectItem in expedients"
                      :key="selectItem.id"
                      :value="isAdmin() ? selectItem.expedient.id : selectItem.id"
                      :label="isAdmin() ? selectItem.expedient.code : selectItem.code"
                  />
                </el-select>
              </div>
              <div class="col-md-3 col-12">
                <el-select  clearable v-model="searchCategory" class="mt-2 w-100" placeholder="Categoría" size="large">
                  <el-option
                      v-for="selectItem in categories"
                      :key="selectItem.id"
                      :value="selectItem.id"
                      :label="selectItem.name"
                  />
                </el-select>
              </div>
              <div class="col-md-3">
                <button
                    @click="handleSearch"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border
                      hover:bg-blue-900 border-1
                      mt-2
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0" v-if="entities && entities.length">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Expediente
                    </th>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Título
                    </th>
                    <th
                        class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Autor
                    </th>
                    <td class="d-none d-lg-table-cell text-center"></td>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in entities" :key="item.id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="{
                                  name: 'Detalle-Document',
                                  params: { id: item.id },
                                }"
                            >
                              {{ item.expedient.code }}
                            </router-link>
                          </h6>
                            <div class="d-block d-lg-none">
                              <a  target="_blank"
                                  class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                                  :href="imageUrl + item.filePath"
                              >
                                <el-icon :size="25"
                                         color="#fff">
                                  <Download />
                                </el-icon>
                              </a>
                              <a target="_blank"
                                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                                  @click.prevent="handleDelete(item.id)"
                              >
                                <el-icon :size="25"
                                         color="#fff">
                                  <Delete />
                                </el-icon>
                              </a>
                            </div>
                        </div>
                      </div>
                    </td>
                    <td class="d-none d-lg-table-cell">
                      {{ item.title }}
                    </td>
                    <td class="d-none d-lg-table-cell">
                    {{ item.author }}
                    </td>
                    <td class="d-none d-lg-table-cell text-center">
                      <a  target="_blank"
                          class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                          :href="imageUrl + item.filePath"
                      >
                        <el-icon :size="25"
                                 color="#fff">
                          <Download />
                        </el-icon>
                      </a>
                      <a
                        class="btn btn-link icon-danger px-3 mb-0 rounded text-danger"
                        href="javascript:;"
                        @click.prevent="handleDelete(item.id)"
                      >
                        <el-icon :size="25"
                                 color="#fff">
                          <Delete />
                        </el-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination v-if="isSuperAdmin()" :pagination="store.state.document.pagination" entity="document" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import { ref } from "vue";
import {Delete, Download} from "@element-plus/icons-vue";
import Pagination from "@/components/Pagination.vue";
import {isAdmin, isParent, isSuperAdmin, isTotal} from "@/services/authGuard";


const store = useStore();
const searchTitle = ref(null);
const searchCategory = ref(null)
const searchExpedient = ref(null)
const categories = computed(() => store.state.documentCategory.entities.data);
const imageUrl = ref(process.env.VUE_APP_URL+'/docs/')
const handleSearch = async () => {
  let payload = {}
  if(searchExpedient.value){
    payload["expedient.id"] = searchExpedient.value
  }

  if(searchTitle.value){
    payload.title = searchTitle.value
  }
  if(searchCategory.value){
    payload["category.id"] = searchCategory.value
  }
  if (isSuperAdmin() || isAdmin()) {
    await store.dispatch("document/getEntities", payload);
  }

  if(isParent()){
    payload["parent.id"] = store.getters['user/getUser'].id
    return await store.dispatch("document/getEntities", payload);
  }
};

const expedients = computed(() => {
  if(isAdmin()){
   return store.state.administratorExpedient.entities.data
  }
  return store.state.expedient.entities.data
});

const getEntities = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("document/getEntities")
  spin.close()
};
onMounted(async () => {
  await getEntities();
  await store.dispatch("documentCategory/getEntities");
});

const entities = computed(
  () => store.state.document.entities.data
);

const deleteEntity = async (position) => {
  try {
    await store.dispatch("document/deleteEntity", position);
    await getEntities();
  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Documento?")
    .then(() => {
      deleteEntity(item);
    })
    .catch((error) => {
      console.error(error);
    });
};
const exportToExcel = async () => {
  if(store.state.document.loading) {
    return
  }
  await store.dispatch('document/setLoading', true)
  await store.dispatch('document/exportList') .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = 'documentos.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading Excel:', error);
      });
  await store.dispatch('document/setLoading', false)
}

</script>

<style scoped lang="scss">
   .input-white {
     border: 1px solid white!important;
     color: white!important;
     option {
       color: black!important;
     }
   }
</style>