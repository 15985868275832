<template>
  <circle-spin :loading="store.state.meeting.loading"></circle-spin>

  <div v-if="currentEntity" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Editar Reunión
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60" v-if="currentEntity">
              <meeting-form :entity="currentEntity" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import MeetingForm from "@/views/meeting/MeetingForm.vue";

const store = useStore();
const route = useRoute();

const getCurrentEntity = async (id) => {
  return await store.dispatch("meeting/getEntityById", id);
};

const currentEntity = computed(() => {
  return store.state.meeting.currentEntity

});

onMounted(()=>{
  getCurrentEntity( route.params.id);
});
</script>
