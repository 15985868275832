<template>
  <div v-if="currentEntity == null">
    Loading
  </div>

  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Editar Solicitud
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <request-form :entity="currentEntity" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import RequestForm from "./RequestForm.vue";

const store = useStore();
const route = useRoute();

const getCurrentEntity = async (id) => {
  return await store.dispatch("request/getEntityById", id);
};

const currentEntity = computed(() => {
  if (!store.state.request.currentEntity) {
    return null;
  }

  return store.state.request.currentEntity;
});

onMounted(()=>{
  getCurrentEntity( route.params.id);
});
</script>
