
export const setEntities = (state, entities) => {
    state.entities = entities
    state.loading = false
}

export const setPagination = (state, pagination) => {
    state.pagination = pagination;
    state.loading = false;
};

export const setCurrentEntity = (state, entity) => {
    state.currentEntity = entity
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}
