import axios from '@/services/api';


export const getEntities = async ({ commit }) => {

    const response = await axios.get('/custodies?pagination=false')

    const { data } = response;

    try {
        if (data) {
            commit('setEntities', data["hydra:member"]);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntityById = async ({ commit }, id) => {

    const response = await axios.get(`/custodies/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {

    return await axios.post("custodies", payload)
        .then((data) => {
            console.log(data);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    return await axios.put("custodies/" + payload['id'], payload)
        .then((response) => response.json())
        .then((data) => {
            commit('setEntities', data.data);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const deleteItem= async ({ commit }, id) => {

    await axios.delete(`/custodies/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};

