<template>
  <form @submit.prevent="handleSubmit" v-if="formModel">
<textarea
    rows="5"
    id="description"
    v-model="formModel.description"
    :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
    placeholder=""
/>
  <p v-if="v.description.$error" class="invalid-feedback mb-0">
    <small>
      {{ v.description.$errors[0].$message }}
    </small>
  </p>
  <div class="mt-3">
    <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
      Enviar
    </button>

    <button
        type="button"
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
    >
      Cancelar
    </button>
  </div>
  </form>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {useStore} from "vuex";

const store = useStore();
const emit = defineEmits(['close','update-list'])
const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref( {
  description: null,
  chatMessage: '/api/chat-messages/'+props.entity.id,
  parentUser: '/api/users/'+store.getters['user/getUser'].id,
}
);

const rules = computed(() => {
  return {
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel.value);
const v = v$.value;

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    }
  }
};
const handleCancel = () => {
  emit('close')
};

const createEntity = async (data) => {
          await store.dispatch("chatReport/add", data).then(() => {
            emit('update-list')
            emit('close')
          }).catch(async error => {
            await Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Se ha producido un error.',
              showConfirmButton: false,
              timer: 3000
            });

            console.log(error);
          })

};
</script>

<style scoped>

</style>