export default () => ({
    loading: true,
    entities : [],
    pagination: null,
    currentUser : null,
    errorMessage: null,
    accessChildInfo: false,
    user: null,
    totals: [],
    userRole: null
})