import axios from '@/services/api';

export const getEntities = async ({ commit }, params) => {
    const response = await axios.get('/documents', { params: params})
    const { data } = response;
    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getAdminEntities = async ({ commit }, params) => {
    const response = await axios.get('/documents_admin', { params: params})
    const { data } = response;
    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntityById = async ({ commit }, id) => {
    commit('setCurrentEntity', null);
    const response = await axios.get(`/documents/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {
    return await axios.post('documents', payload)
        .then((response) => response)
        .catch((e) => e.response);
};

export const edit = async (_, payload) => {
    return await axios.put('documents/' + payload['id'], payload)
        .then((response) => response)
        .catch((e) => e.response);
};

/* export const removeImage = async (_, id) => {
    return await axios.delete('natural_space_images/' + id)
        .then((response) => response)
        .catch((e) => e.response);
}; */

export const deleteEntity= async ({ commit }, id) => {
    await axios.delete(`/documents/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};
export const setLoading= async ({ commit }, val) => {
    commit('setLoading', val);
};
export const exportList = async () => {
    return axios({
        method: "get",
        url: process.env.VUE_APP_URL + "/api/documents/export.xlsx",
        responseType: "blob",
    });
}