import axios from '@/services/api';

export const getEntities = async ({ commit }, params) => {
    const response = await axios.get("/parent-users", { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntitiesNoPaginate = async ({ commit }, params) => {
    const response = await axios.get("/parent-users?pagination=false", { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data["hydra:member"]});
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntityById = async ({ commit }, id) => {
    commit('setCurrentEntity', null);
    const response = await axios.get(`/parent-users/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {
    return await axios.post('parent-users', payload)
        .then((response) => response)
        .catch((e) => e.response);
};

export const edit = async (_, payload) => {
    return await axios.put('parent-users/' + payload['id'], payload)
        .then((response) => response)
        .catch((e) => e.response);
};

/* export const removeImage = async (_, id) => {
    return await axios.delete('natural_space_images/' + id)
        .then((response) => response)
        .catch((e) => e.response);
}; */

export const deleteEntity= async ({ commit }, id) => {
    await axios.delete(`/parent-users/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};

export const ban= async ({ commit }, payload) => {
    await axios.post(`/users/ban/${payload.id}`, payload)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};

export const exportMyPdf = async () => {
   return axios({
       method: "get",
       url: process.env.VUE_APP_URL + "/api/users/me.pdf",
       responseType: "blob",
   });
}