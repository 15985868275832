import axios from "axios";
import router from "../router"; '../router/index';
const api = axios.create({
    baseURL: process.env.VUE_APP_URL_API
});

api.interceptors.request.use(
    async config => {
        const token = sessionStorage.getItem('idToken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
            //  config.headers.Accept =  'application/json'
        } else {
            await router.push({name: 'Login'});
        }
        return config;
    },
    () => {
        router.push({name: 'Login'});
    });


api.interceptors.response.use(response => {
    return response;
}, error => {
    if (!error.response || error.response.status === 401) {
        localStorage.removeItem("idToken")
        router.push({name: 'Login'});
    }
    return error;
});

export default api;
