<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Nuevo Familiar
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <parent-form :entity="formModel" action="create" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ParentForm from "./ParentForm.vue";

const formModel = ref({
  firstName: null,
  lastName: null,
  personalInfo : {
    dni: null,
    gender: null,
    ssn: null,
    dateOfBirth: null,
  },
  address : {
    street: null,
    number: null,
    door: null,
    floor: null,
    ladder: null,
    zipCode: null,
  },
  city: null,
  photo: null,

});
</script>
