<template>
  <form @submit.prevent="handleSubmit" v-if="formModel">
    <div class="row">
      <div class="col-md-6 mt-5">
        <label for="message" class="form-control-label">
          Mensaje<span class="required">*</span>
        </label>

        <textarea
            rows="5"
          id="message"
          v-model="formModel.text"
          :class="[
                        'form-control',
                        v.text.$error ? 'is-invalid' : '',
                      ]"
          placeholder=""
        />
        <p v-if="alertMessage" class="invalid-feedback mb-0">
          <small>
            {{ alertMessage }}
          </small>
        </p>
        <p v-if="v.text.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.text.$errors[0].$message }}
          </small>
        </p>
        <div class="mt-3">
          <button
              :disabled="loading"
              type="submit"
              class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
            <span v-if="!loading"> Enviar</span>
            <span v-else> Enviando</span>
          </button>

          <button
              v-if="formModel && formModel.text"
              type="button"
              class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
              @click="handleCancel"
          >
            Cancelar
          </button>
        </div>
    </div>

    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, defineEmits, computed, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {isAdmin, isParent} from "@/services/authGuard";
import {ElLoading} from "element-plus";

const store = useStore();
const router = useRouter();
const emit = defineEmits(['update-list'])
const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {
  text: null,
  expedient: null,
  senderParent: isParent() ? store.getters['user/getUser']['@id'] : null,
  administrator: !isParent() ? store.getters['user/getUser']['@id'] : null,
}
);

const rules = computed(() => {
  return {
    text: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel.value);
const v = v$.value;
const alertMessage = ref(null)

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    }
  }
};

const loading = ref(false)
const handleCancel = () => {
  formModel.value.text = null
  router.push({ name: 'Chats', params: {} });
};

watch(formModel.value, () => {
  alertMessage.value = null
})
const createEntity = async (data) => {
  loading.value = true
  const text = {
    comment: { text: data.text },
    languages: ['es'],
    requestedAttributes: {
      TOXICITY: {},
    },
  };
  const apiKey = 'AIzaSyD0jbz4qtuhM_O2TMln6hYWqCAReJ7Gtu0';
  const apiUrl = 'https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze';
  const spin = ElLoading.service({
    lock: true,
    text: 'Analizando contenido',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  fetch(`${apiUrl}?key=${apiKey}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(text),
  })
      .then((response) => response.json())
      .then(async (result) => {
        spin.close()
        // Handle the API response here
        if(result.attributeScores.TOXICITY.spanScores[0].score.value < 0.50){
          if(isParent()){
            data.senderParent = '/api/parent-users/' +store.getters['user/getUser'].id
          }
          if(isAdmin()){
            data.administrator = '/api/administrators/' +store.getters['user/getUser'].id
          }
          data.expedient = props.entity.expedient
          const spin2 = ElLoading.service({
            lock: true,
            text: 'Enviando',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          await store.dispatch("chat/add", data).then(() => {
            emit('update-list')
            v$.value.$reset()
            formModel.value.text = null
            loading.value = false
            spin2.close()
          }).catch(async error => {
            await Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Se ha producido un error.',
              showConfirmButton: false,
              timer: 3000
            });
            loading.value = false
            spin2.close()
            console.log(error);
          })
        }else{
          spin.close()
          loading.value = false
         alertMessage.value = 'Hemos detectado contenido en un lenguaje potencialmente ofensivo o no apropiado.'
        }

      })
      .catch((error) => {
        console.error('Error:', error);
      });

};
</script>
