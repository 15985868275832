import axios from '@/services/api';

export const getEntities = async ({ commit }, params = null) => {
    commit('setLoading', true);
    let response = await axios.get('/expedients', { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }
    commit('setLoading', false);
};
export const getEntitiesNoPaginate = async ({ commit }, params) => {
    const response = await axios.get("/expedients?pagination=false", { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data["hydra:member"]});
        }

    } catch (e) {
        console.log(e);
    }

};
export const getEntityById = async ({ commit }, id) => {
    commit('setCurrentEntity', null);
    const response = await axios.get(`/expedients/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {
    return await axios.post('expedients', payload)
        .then((response) => response)
        .catch((e) => e.response);
};

export const edit = async (_, payload) => {
    return await axios.put('expedients/' + payload['id'], payload)
        .then((response) => response)
        .catch((e) => e.response);
};

/* export const removeImage = async (_, id) => {
    return await axios.delete('natural_space_images/' + id)
        .then((response) => response)
        .catch((e) => e.response);
}; */

export const deleteEntity= async ({ commit }, id) => {
    await axios.delete(`/expedients/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};

export const setLoading= async ({ commit }, val) => {
    commit('setLoading', val);
};

export const exportPdf = async (_, id) => {
    return axios({
        method: "get",
        url: process.env.VUE_APP_URL + "/api/expedients/export-pdf/"+id,
        responseType: "blob"
    });
}

export const exportList = async () => {
    return axios({
        method: "get",
        url: process.env.VUE_APP_URL + "/api/expedients/export.xlsx",
        responseType: "blob",
    });
}