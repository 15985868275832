import { createStore } from "vuex";

import auth from "./auth";
import user from "./user";
import theme from "./theme";
import expedient from "./expedient";
import province from "./province";
import chat from "./chat";
import child from "./child";
import parent from "./parent";
import event from "./event";
import document from "./document";
import documentCategory from "./documentCategory";
import request from "./request";
import agreement from "./agreement";
import sentence from "./sentence";
import city from "./city";
import eventCategory from "./eventCategory";
import administratorEvent from "./administratorEvent";
import parentEvent from "./parentEvent";
import administratorExpedient from "./administratorExpedient";
import meeting from "@/store/meeting";
import situation from "@/store/situation";
import relation from "@/store/relation";
import custody from "@/store/custody";
import notification from "@/store/notification";
import community from "@/store/community";
import chatReport from "@/store/chatReport";
import documentReport from "@/store/documentReport";

const store = createStore({
  modules: {
    auth,
    expedient,
    child,
    parent,
    province,
    user,
    theme,
    chat,
    chatReport,
    event,
    document,
    documentCategory,
    request,
    agreement,
    sentence,
    city,
    eventCategory,
    administratorEvent,
    parentEvent,
    administratorExpedient,
    meeting,
    situation,
    relation,
    custody,
    notification,
    community,
    documentReport
  }
});

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  sessionStorage.setItem('store', JSON.stringify(state.auth));
});

export default store;
