<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="reason" class="form-control-label">
          Motivo<span class="required">*</span>
        </label>
        <textarea
            id="reason"
            v-model="formModel.reason"
            :class="[
                        'form-control',
                        v.reason.$error ? 'is-invalid' : '',
                      ]"
            placeholder="Díganos por que desea darse de baja."
        />
        <p v-if="v.reason.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.reason.$errors[0].$message }}
          </small>
        </p>
        <label for="user_active" class="form-control-label w-100">
          Tipo de baja
        </label>
        <el-select
            v-model="formModel.enabled"
            size="large"
            class="w-100"
        >
          <el-option :value="1" label="Temporal" />
          <el-option :value="2" label="Total"/>
        </el-select>
      </div>
    </div>
    <div class="mt-3 text-right flex justify-content-end gap-2">
      <button
          @click="handleSubmit"
          type="submit"
          class="btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        Enviar
      </button>

      <button
          class="ml-2 btn btn-primary
                                            text-danger
                                            bg-white
                                            border border-danger
                                            rounded-lg
                                            hover:bg-blue-900"
      >
        Cancelar
      </button>
    </div>

  </form>

</template>

<script setup>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {clearErrors} from "@/utils/errorsUtils";

const store = useStore();
const router = useRouter();

const formModel = ref( {
  reason: null,
  type: null
});

const rules = computed(() => {
  return {
    reason: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    type: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
  }
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
v.locale = 'es'

const handleSubmit = () => {
  v$.value.$validate();
  createEntity(formModel.value);
};


const createEntity = async () => {
  await store.dispatch("user/down").then(async (res) => {
    if (res.status === 200 || res.status === 201) {
      await Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud de baja enviada.',
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      await Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: clearErrors(res),
        showConfirmButton: false,
        timer: 3000
      })
    }
  });
  await router.push({name: "Editar-Perfil", params: {id: store.getters['user/getUser'].id}});
}



</script>
