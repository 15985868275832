<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title mb-0">Documentos</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-12 py-2">
          <el-input
              clearable
              type="text"
              placeholder="Título"
              v-model="searchTitle"
          />
        </div>
        <div class="col-md-3 col-12">
          <el-select  clearable v-model="searchCategory" class="m-2" placeholder="Categoría" size="large">
            <el-option
                v-for="selectItem in categories"
                :key="selectItem.id"
                :value="selectItem['@id']"
                :label="selectItem.name"
            />
          </el-select>
        </div>
        <div class="col-md-3">
          <button
              @click="handleSearch"
              class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                      mt-2
                    ">
            Buscar
          </button>
        </div>
      </div>
      <table class="table align-items-center mb-0">
        <thead>
        <tr>
          <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
            Documento
          </th>
          <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
            Categoría
          </th>
          <td class="d-none d-lg-table-cell text-center"></td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="item in entities" :key="item.id">
          <td>
            <div class="d-flex">
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm wrap-balance">
                  <a  target="_blank"
                      class="btn btn-link icon-primary px-3 mb-0 rounded text-primary d-block d-lg-none text-start"
                      :href="imageUrl + item.filePath"
                  >
                    <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                      <Download />
                    </el-icon>
                    {{ truncate(item.title,50) }}
                  </a>
                  <router-link
                      class="d-none d-lg-block"
                      :to="{
                                  name: 'Detalle-Document',
                                  params: { id: item.id },
                                }"
                  >
                    {{ truncate(item.title,50) }}
                  </router-link>
                </h6>
              </div>
            </div>
          </td>
          <td class="">
            <div class="wrap-balance">{{ item.category.name }}</div>
          </td>
          <td class="d-none d-lg-table-cell text-center">
            <a  target="_blank"
                class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                :href="imageUrl + item.filePath"
            >
              <el-icon :size="25"
                       color="#fff">
                <Download />
              </el-icon>
              Descargar
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <a @click="showFormDialog()"
         class="col-md-4 col-6 col-md-offset-8 mt-3 btn btn-primary btn-sm text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto add-button">
        <el-icon :size="20"
                 color="#fff">
          <Upload />
        </el-icon>
        Agregar Documento
      </a>
    </div>
  </div>
  <el-dialog class="dd" v-model="showForm" title="Crear documento">
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col">
          <label for="title" class="form-control-label">
            Título<span class="required">*</span>
          </label>
          <input
              id="title"
              v-model="formModel.title"
              :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.title.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.title.$errors[0].$message }}
            </small>
          </p>
          <label for="description" class="form-control-label">
            Descripción<span class="required">*</span>
          </label>

          <input
              id="description"
              v-model="formModel.description"
              :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
              type="text"
              placeholder=""
          />

          <p v-if="v.description.$error" class="invalid-feedback mb-0">
            <small>
              {{ v.description.$errors[0].$message }}
            </small>
          </p>
          <el-row class="mt-3" :gutter="25">
             <el-col :md="12">
               <label for="category" class="form-control-label w-100">
                 Categoría<span class="required">*</span>
               </label>
               <el-select  clearable v-model="formModel.category" class="w-100" placeholder="Categoría" size="large">
                 <el-option
                     v-for="selectItem in categories"
                     :key="selectItem.id"
                     :value="selectItem['@id']"
                     :label="selectItem.name"
                 />
               </el-select>
               <p v-if="v.category.$error" class="invalid-feedback mb-0">
                 <small>
                   {{ v.category.$errors[0].$message }}
                 </small>
               </p>
             </el-col>
            <el-col :md="12">
              <label for="document" class="form-control-label w-100">
                Documento<span class="required">*</span>
              </label>
              <input
                  type="file"
                  id="video"
                  @change="uploadFile"
                  :class="[ 'form-control']"
                  placeholder=""
              />
              <p v-if="v.file.$error" class="invalid-feedback mb-0">
                <small>
                  {{ v.file.$errors[0].$message }}
                </small>
              </p>
            </el-col>
          </el-row>

          <div class="mt-3">
            <button
                type="submit"
                class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
              Crear
            </button>

            <button
                class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                @click="handleCancel()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </form>
  </el-dialog>
</template>

<script setup>
import {ref, defineProps, onMounted, computed} from "vue";
import { useStore } from "vuex";
import {Upload, Download} from "@element-plus/icons-vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import {isParent} from "@/services/authGuard";
import {clearErrors} from "@/utils/errorsUtils";

const store = useStore();
const props = defineProps({
  entity: {
    type: Object,
    required: true
  }
})
const searchTitle = ref(null);
const searchCategory = ref(null)
const showForm= ref(false)
const imageUrl = ref(process.env.VUE_APP_URL+'/docs/')

const formModel = ref( {
  title: null,
  category: null,
  description: null,
  file: null,
  expedient: props.entity['@id'],
  parentUser: isParent() ? store.getters['user/getUser']['@id'] : null,
  author: store.getters['user/getUser'].fullName
});

const categories = computed(() => store.state.documentCategory.entities.data);
const entities = computed(
    () =>   store.state.document.entities.data
);

const getEntities = async () => {
  return await store.dispatch("document/getEntities",{
    "expedient.id": props.entity.id
  });
};

onMounted(()=> {
  getEntities();
  store.dispatch("documentCategory/getEntitiesNoPaginate");
});
const showFormDialog = () => {
  clearForm()
  showForm.value = true
}
const rules = computed(() => {
  return {
    category: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    file: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});
const clearForm = () => {
  formModel.value = {
    title: null,
    category: null,
    description: null,
    file: null,
    locked: true,
    expedient: props.entity['@id'],
    parentUser: isParent() ? store.getters['user/getUser']['@id'] : null,
    author: store.getters['user/getUser'].fullName
  }
};
const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const uploadFile = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.readAsDataURL(files[i]);
    reader.onload = function(e) {
      formModel.value.file = e.target.result;
      // formModel.value.filePath = e.target.result;
    };
  }
}

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    createEntity( formModel.value);
  }
};
const handleCancel = () => {
  showForm.value= false
};

const createEntity = async (data) => {
    await store.dispatch("document/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        showForm.value = false
        clearForm()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Documento creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
        await getEntities();
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });

};

const handleSearch = () => {
  let payload = {
    "expedient.id": props.entity.id
  }
  if(searchTitle.value){
    payload.title = searchTitle.value
  }
  if(searchCategory.value){
    payload["category.id"] = searchCategory.value
  }
  store.dispatch("document/getEntities",payload);
};

const truncate = (value, length) => {
  if (!value) return "";
  if (value.length <= length) return value;
  return value.substring(0, length) + "...";
};

</script>
