<template>
   <div class="card">
     <div class="card-header" v-if="hasTitle">
         <h3 class="card-title mb-0">Expedientes activos</h3>
     </div>
     <div class="card-body">
       <div class="row">
         <div class="col-md-3 col-12 py-2">
           <el-input
               clearable
               type="text"
               placeholder="Buscar"
               v-model="searchName"
           />
         </div>
         <div class="col-md-9 col-12 py-2">
           <a @click="showExpedientForm = true"
                        class="btn btn-primary text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-aut o add-button pull-right">
             <el-icon :size="20"
                      color="#fff">
               <CirclePlus />
             </el-icon> Nuevo Expediente
           </a>
         </div>
       </div>
         <div class="table-responsive p-0">
           <table class="table align-items-center mb-0" v-if="entities && entities.length > 0 && !loading">
             <thead>
             <tr>
               <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
                 Expediente
               </th>
               <th class="text-uppercase text-custom text-xxs text-center font-weight-bolder opacity-7">
                 Detalles
               </th>
               <td class="d-none d-lg-table-cell text-center"></td>
             </tr>
             </thead>

             <tbody>
             <tr v-for="item in entities" :key="item.id">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex flex-column justify-content-center">
                     <h6 class="mb-0 text-sm wrap-balance">
                       <router-link
                           :to="{
                                  name: 'Detalle-Expedient-Admin',
                                  params: { id: item.id },
                                }"
                       >
                      <img src="@/assets/img/expedients-black.svg" alt="">   {{ item.code }} -- {{ item.mainParent.fullName }} / {{ item.secondaryParent.fullName }}
                       </router-link>
                     </h6>
                   </div>
                 </div>
               </td>
               <td class="text-center">
                 <router-link
                     class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                     :to="{
                            name: 'Detalle-Expedient-Admin',
                            params: { id: item.id },
                          }"
                 >  <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                   <View />
                 </el-icon>
                 </router-link>
                 <a
                     class="btn btn-link icon-danger px-3 mb-0 rounded text-danger"
                     href="javascript:;"
                     @click="handleDelete(item.id)"
                 >
                   <el-icon :size="25"
                            color="#fff">
                     <Delete />
                   </el-icon>
                 </a>
               </td>
             </tr>
             </tbody>
           </table>
           <el-alert  :closable="false" v-if="(!entities || entities.length === 0) && !loading">
             <p>No tienes expedientes asignados en estos momentos.</p>
           </el-alert>
         </div>
       </div>
     <el-dialog class="dd" v-model="showExpedientForm" title="Crear Expediente">
       <form @submit.prevent="handleExpedientSubmit">
         <div class="row">
           <div class="card mt-3">
             <div class="card-header white">
               <h3 class="card-title text-primary">Familiares</h3>
             </div>
             <div class="card-body pt-0">
               <div class="row">
                 <div class="col-md-6">
                   <label for="mainParent" class="form-control-label w-100">
                     Familiar<span class="required">*</span>
                   </label>
                   <el-select
                       v-model="formModel.mainParent"
                       size="large"
                   >
                     <el-option
                         v-for="selectItem in parents"
                         :key="selectItem.id"
                         :value="selectItem['@id']"
                         :label="selectItem.fullName"
                     />
                   </el-select>
                   <button class="btn btn-primary d-block mt-2 btn-sm" @click.prevent="showParentForm = true; activeParent='main';">Crear nuevo</button>
                   <p v-if="v.mainParent.$error" class="invalid-feedback mb-0">
                     <small>
                       {{ v.mainParent.$errors[0].$message }}
                     </small>
                   </p>
                 </div>
                 <div class="col-md-6">
                   <label for="secondaryParent" class="form-control-label w-100">
                     Familiar<span class="required">*</span>
                   </label>
                   <el-select
                       v-model="formModel.secondaryParent"
                       size="large"
                   >
                     <el-option
                         v-for="selectItem in parents"
                         :key="selectItem.id"
                         :value="selectItem['@id']"
                         :label="selectItem.fullName"
                     />
                   </el-select>
                   <button class="btn btn-primary d-block mt-2 btn-sm" @click.prevent="showParentForm = true; activeParent='secondary';">Crear nuevo</button>
                   <p v-if="v.secondaryParent.$error" class="invalid-feedback mb-0">
                     <small>
                       {{ v.secondaryParent.$errors[0].$message }}
                     </small>
                   </p>
                 </div>
               </div>
             </div>
           </div>
           <div class="mt-3">
             <button
                 type="submit"
                 class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
              Crear
             </button>

             <button
                 class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                 @click.prevent="handleCancel()"
             >
               Cancelar
             </button>
           </div>
         </div>
       </form>
     </el-dialog>
     <el-dialog class="dd" v-model="showParentForm" title="Crear Familiar">
       <parent-form action="create"  @close-form="handleCancelSubForms" @add-parent="addParent" />
     </el-dialog>
   </div>
</template>

<script setup>
import { computed, onMounted,defineProps } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import { ref, watch } from "vue";
import {View, Delete, CirclePlus} from "@element-plus/icons-vue";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ParentForm from "@/views/dashboard/ParentForm.vue";
import {clearErrors} from "@/utils/errorsUtils";


const store = useStore();
const searchName = ref("");
const showExpedientForm = ref(false);
const showParentForm = ref(false);
const loading = ref(true)
const parents = computed(() => store.state.parent.entities.data);
const activeParent = ref('main')
defineProps({
     hasTitle: {
        type: Boolean,
        required: false,
        default: true
     }
})

const formModel = ref( {
  children: [],
  administrators: [store.getters['user/getUser'].id],
});

watch(searchName, async () => {
  handleSearch()
})
const handleCancelSubForms = () => {
  showParentForm.value = false
};
const handleCancel = () => {
  clearForm()
  showExpedientForm.value = false
};
const getExpedients = async () => {
  loading.value = true
  await store.dispatch("expedient/getEntities").then(()=>{
    loading.value = false
  });
};

onMounted(()=> {
  getExpedients();
  store.dispatch("city/getEntities");
  store.dispatch("parent/getEntities");
});

const addParent = (id) => {
  store.dispatch("parent/getEntities");
  if(activeParent.value === 'main'){
      formModel.value.mainParent = id
  }else{
    formModel.value.secondaryParent = id
  }
}
const rules = computed(() => {
  return {
    mainParent: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    secondaryParent: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  }
});

const clearForm = () => {
  formModel.value = {
    code: null,
    mainParent: null,
    secondaryParent: null,
    administrators: [store.getters['user/getUser'].id]
  }
  v.$reset()
};
const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const entities = computed(
    () => store.state.expedient.entities.data
);
const deleteEntity = async (position) => {
  try {
    await store.dispatch("expedient/deleteEntity", position);
    await getExpedients();
  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Expediente?")
      .then(() => {
        deleteEntity(item);
      })
      .catch((error) => {
        console.error(error);
      });
};
const createExpedient = async (data) => {
  const spin = ElLoading.service({
    lock: true,
    text: 'Creando',
    background: 'rgba(0, 0, 0, 0.7)',
  })
    await store.dispatch("expedient/add", data).then(async (res) => {
      if (res.status === 200 || res.status === 201) {
        spin.close()
        showExpedientForm.value = false
        clearForm()
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Expediente creado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
        await getExpedients()
      } else {
        spin.close()
        showExpedientForm.value = false
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    })
}

const handleExpedientSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    createExpedient( formModel.value);
  }
};
const handleSearch = () => {
  store.dispatch("expedient/getEntities", {
    'expedient.child.firstName': searchName.value,
   // 'expedient.child.lastName': searchName.value,
   // 'administrator.id': store.getters['user/getUser'].id
  });
};
</script>

<style scoped>

</style>
