<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="name" class="form-control-label">
          Nombre<span class="required">*</span>
        </label>

        <input
          id="name"
          v-model="formModel.name"
          :class="[
                        'form-control',
                        v.name.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />

        <p v-if="v.name.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.name.$errors[0].$message }}
          </small>
        </p>
    </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {clearErrors} from "@/utils/errorsUtils";

const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {});


const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.name = null
};

const handleCancel = () => {
  clearForm()
  router.push({ name: 'DocumentCategories', params: {} });
};

const updateEntity = async (data) => {
    await store.dispatch("documentCategory/edit", data).then(async (res) => {
      if(res.status && (res.status === 200 || res.status === 201)){
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Categoría editada satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }

    });
    await router.push({name: 'DocumentCategories', params: {}});
};

const createEntity = async (data) => {
  try {
    await store.dispatch("documentCategory/add", data).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Categoría creada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
    });

    await router.push({ name: 'DocumentCategories', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

</script>
