<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div v-if="expedients && expedients.length >1">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">
              Expedientes
            </h3>
          </div>
          <div class="card-body">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                <tr>
                  <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
                  </th>
                  <th class="text-uppercase text-custom text-xxs text-center font-weight-bolder opacity-7">
                  </th>
                </tr>
                </thead>

                <tbody  v-if="!loading">
                <tr  @click="selectExpedient(item.expedient ? item.expedient : item )" v-for="item in expedients" :key="item.id"  :class="selectedExpedient && (item.id === selectedExpedient.id || (item.expedient && (item.expedient.id === selectedExpedient.id))) ? 'bg-primary color-white' : 'bg-white color-black'" class="cursor-pointer">
                  <td style="padding-left: 0!important;">
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm wrap-balance">
                          <img v-if="!selectedExpedient" src="@/assets/img/expedients-black.svg" alt="">
                          <img v-else src="@/assets/img/expedients.svg" alt="">
                          <span :class="(!selectedExpedient || (item.expedient ? item.expedient.id !== selectedExpedient.id : item.id !==  selectedExpedient.id )) ? 'text-black' : 'text-white'" v-if="item.expedient"> {{ item.expedient.code }} -- {{ item.expedient.mainParent.fullName }} / {{ item.expedient.secondaryParent.fullName }}</span>
                          <span :class="(!selectedExpedient || (item.expedient ? item.expedient.id !== selectedExpedient.id : item.id !==  selectedExpedient.id )) ? 'text-black' : 'text-white'" v-else> {{ item.code }} -- {{ item.mainParent.fullName }} / {{ item.secondaryParent.fullName }}</span>
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                        class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                    > <el-icon :size="25"
                               class="mr-2"
                               color="#fff">
                      <View />
                    </el-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3" v-if="selectedExpedient">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Chats
                </h3>
                <div class="sub-line"></div>
              </div>
            </div>
          </div>

          <div class="card-body pt-3 pb-2">
            <div class="row">
              <div class="col-xl-6" v-if="!loading">
                <div class="row col-12 mb-3" v-for="message in entities" :key="message.id"  :class="alg(message)">
                  <ChatMessage @update-list="getEntities" :message="message" class=" mb-4"  />
                </div>
              </div>
              <div class="col-12 pt-5" v-if="typeof expedients !== 'undefined' && expedients.length >0 && selectedExpedient">
                <chat-form v-if="( isParent() && !selectedExpedient.chatLocked) || ( isAdmin() && !selectedExpedient.chatLocked) " action="create" @update-list="getEntities"
                           :entity="{
                               text: null,
                               expedient: selectedExpedient['@id'],
                               senderParent: isParent() ? store.getters['user/getUser']['@id'] : null,
                               administrator: isAdmin() ? store.getters['user/getUser']['@id'] : null}" />
              </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import { useStore } from "vuex";
import {isAdmin, isParent} from "@/services/authGuard";
import ChatForm from "@/views/chat/ChatForm.vue";
import ChatMessage from "@/views/chat/components/ChatMessage.vue";
import {View} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";

const store = useStore();
//const searchName = ref("");
const selectedExpedient = ref(null);
let entities = computed(
    () => store.state.chat.entities.data
);
const loading = ref(true);
let expedients = computed(
    () => isAdmin() ? store.state.administratorExpedient.entities.data : store.state.expedient.entities.data
);
/*const handleSearch = () => {
  store.dispatch("chat/getEntities", {
    "expedient.id": searchName.value
  });
};*/
const getExpedients = async () => {
  loading.value = true
  if(isAdmin()){
    return await store.dispatch("administratorExpedient/getEntities", {
      "administrator.id": store.getters['user/getUser'].id
    });
  }
  if(isParent()) {
    await store.dispatch("expedient/getEntities");
  }
  loading.value = false
};
const selectExpedient = async item => {
  if(item.expedient){
    selectedExpedient.value = item.expedient
  }else{
    selectedExpedient.value = item
  }

  await getEntities()
}
const getEntities = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  if(isParent()){
    if(expedients.value.length === 1){
      selectedExpedient.value = expedients.value[0]
    }
    if(selectedExpedient.value){
      await store.dispatch("chat/getEntities",{
        "expedient.id": selectedExpedient.value.id
      });
    }

  }
  if(isAdmin()){
    if(expedients.value.length === 1){
      selectedExpedient.value = expedients.value[0].expedient
    }
    if(selectedExpedient.value) {
      await store.dispatch("chat/getEntities", {
        "expedient.id": selectedExpedient.value.id
      });
    }
  }
  spin.close()
};
const alg = (message) => {
  return (message.senderParent && store.getters['user/getUser'].id ===   message.senderParent.id)
  || (message.administrator && store.getters['user/getUser'].id ===   message.administrator.id) ?
      'pull-right' : ''
};
onMounted(async () => {
  loading.value = true
  await getExpedients().then(() => {
    getEntities();
  });
  loading.value = false
});


</script>
