<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <div class="flex flex-row justify-content-between">
                <h3 class="card-title mb-0">
                  Expediente {{ currentEntity.code }}
                </h3>
                <div class="flex flex-row justify-content-between" style="width: 250px;">
                <div class="mt-2">
                  <router-link   :to="{
                                  name: 'Actualizar-Expedient',
                                  params: {  id: currentEntity.id },
                                }"
                                 class="btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                    Editar
                  </router-link>
                </div>
                <div class="mt-2">
                  <a href="" @click.prevent="exportToPdf()"
                     class="btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                    Exportar
                  </a>
                </div>
                </div>
              </div>


              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Dashboard' }"
                             class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm  border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>

            </div>
          </div>

          <div class="card-body" v-if="currentEntity">
            <div class="row">
              <div class="col-md-6">
                <expedient-members :current-entity="currentEntity"
                                   :id="isAdmin() ? currentEntity.id : currentEntity.id" />
                <expedient-administrators class="mt-3"
                                          :expedient="currentEntity"
                                          :administrators="currentEntity.administrators" />
              </div>
              <div class="col-md-6">
                <admin-expedient-documents :entity="currentEntity" />
              </div>
              <div class="row  mt-3">
                <div class="col-md-12">
                  <expedients-events :current-entity="currentEntity" @update-entity="getCurrentExpedient" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>

import { useStore } from "vuex";
import {computed, onMounted, ref} from "vue";
import { useRoute } from "vue-router";
import AdminExpedientDocuments from "@/views/expedient/AdminExpedientDocuments.vue";
import 'dayjs/locale/en';
import ExpedientMembers from "@/views/expedient/components/ExpedientMembers.vue";
import ExpedientAdministrators from "@/views/expedient/components/ExpedientAdministrators.vue";
import ExpedientsEvents from "@/views/expedient/components/ExpedientsEvents.vue";
import {isAdmin} from "@/services/authGuard";
import {ElLoading} from "element-plus";


const route = useRoute();
const store = useStore();

const currentEntity = computed(() => store.state.expedient.currentEntity);
const loading = ref(false)
const getCurrentExpedient = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch('expedient/getEntityById', route.params.id).finally(()=> spin.close());
};

onMounted(() => {
  getCurrentExpedient()
});
const exportToPdf = async () => {
  if(loading.value) {
    return
  }
  loading.value = true
  await store.dispatch('expedient/exportPdf', currentEntity.value.id) .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = currentEntity.value.code+'.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading PDF:', error);
      });
  loading.value = false
}
</script>
