import axios from '@/services/api';
//import jwtDecode from "jwt-decode";

export const initializeStore = async ({ commit }) => {
    commit('initialiseStore');
};

export const login = async ({ commit }, user) => {
    return await axios.post('/auth', {
        email: user.email,
        password: user.password
    }).then( response => {
        if(response.status && response.status === 200){
            const { data } = response;
            const { token } = data;

            if (token) {
                commit('loginUser', { user, token })
                sessionStorage.setItem('idToken', token);

                commit('setUsername', user.firstname);
                commit('setIdToken', token);

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }else{
            return response.response
        }
        return response

    }).catch(error => error)
}

export const getToken = () => {
    return sessionStorage.getItem('idToken');
}

export const logout = ({ commit }) => {
    commit('logout');
};

// export const register = ({ commit }, user) => {
//     return Auth.register(user).then(
//         response => {
//             commit('registerSuccess');
//             return Promise.resolve(response.data);
//         },
//         error => {
//             commit('registerFailure');
//             return Promise.reject(error);
//         }
//     );
// }
