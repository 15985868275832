<template>
  <div v-if="store.state.user.currentUser" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h3 class="card-title text-white">Mi perfil</h3>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <div class="row">
                <div class="col-lg-6">
                  <form @submit.prevent="handleSubmit">
                    <div class="row">
                      <div class="col-md-12">
                        <label for="user_firstName" class="form-control-label">
                          Nombre
                        </label>
                        <input
                            id="user_firstName"
                            v-model="formModel.firstName"
                            :class="[
                        'form-control',
                        v.firstName.$error ? 'is-invalid' : '',
                      ]"
                            type="text"
                            placeholder=""
                        />
                        <p v-if="v.firstName.$error" class="invalid-feedback mb-0">
                          <small>
                            {{ v.firstName.$errors[0].$message }}
                          </small>
                        </p>
                        <label for="user_lastName" class="form-control-label">
                          Apellido
                        </label>
                        <input
                            v-model="formModel.lastName"
                            id="user_lastName"
                            :class="[
                        'form-control',
                        v.lastName.$error ? 'is-invalid' : '',
                      ]"
                            type="text"
                            placeholder=""
                        />
                        <p v-if="v.lastName.$error" class="invalid-feedback mb-0">
                          <small>
                            {{ v.lastName.$errors[0].$message }}
                          </small>
                        </p>
                        <label for="user_email" class="form-control-label">
                          Email
                        </label>
                        <input
                            id="user_email"
                            :value="formModel.email"
                            class="form-control"
                            type="text"
                            placeholder=""
                            disabled
                        />
                      </div>
                    </div>
                    <div class="mt-3">
                      <button
                          type="submit"
                          class="
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                      >
                        Guardar cambios
                      </button>
                      <button
                          class="
                      me-2 mx-3
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                          @click="handleCancel"
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                      <div class="col-md-12">
                        <template  v-if="isParent()">
                          <label for="photo" class="form-control-label w-100 mt-3">
                            Quero recibir notificaciones
                          </label>
                          <el-switch @change="handleConfigSubmit" v-model="formConfigModel.enableNotify" />
                        </template>
                        <label for="photo" class="form-control-label w-100 mt-5" v-if="isParent()">
                          <el-button type="danger" @click="showDownMessage = true">Quiero darme de baja</el-button>
                        </label>
                        <el-dialog class="dd" v-model="showDownMessage" title="Solicitud de baja">
                           <!-- <p>¿Está seguro que desea darse de baja de nuestra plataforma?</p>
                            <p>Sen enviará un email a nuestros profesionales para que procesen su solicitud y será notificado en cuanto ea efectiva</p>
                            -->
                           <UserLeaveForm />
                        </el-dialog>
                      </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {isParent} from "@/services/authGuard";
import Swal from "sweetalert2";
import {clearErrors} from "@/utils/errorsUtils";
import UserLeaveForm from "@/views/user/UserLeaveForm.vue";
import {ElLoading} from "element-plus";


const store = useStore();
const router = useRouter();
const route = useRoute();

const rules = computed(() => {
  return {
    firstName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    lastName: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const getCurrentUser = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("user/getCurrentUser", id).finally(()=>spin.close())
};
const formModel = ref({})
const formConfigModel = ref({})
const showDownMessage = ref(false)
onMounted(async () => {
  await getCurrentUser(route.params.id)
  formModel.value = store.state.user.currentUser
});


const v$ = useVuelidate(rules, formModel);
const v = v$.value;

const handleConfigSubmit = () => {
  updateUserConfig(formModel.value);
};
const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    updateUser(formModel.value);
  }
};

const updateUser = async (user) => {
  try {
    await store.dispatch("user/edit", user).then(async res => {
      if (res.status === 200 || res.status === 201) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Perfil editado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
      }else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        })
      }
    });
    await router.push({ name: "Editar-Perfil", params: {id:route.params.id} });
  } catch (error) {
    console.log(error);
  }
};

const updateUserConfig= async (user) => {
  try {
    await store.dispatch("user/config", user);
    await router.push({ name: "Editar-Perfil", params: {id:route.params.id} });
  } catch (error) {
    console.log(error);
  }
};


const handleCancel = () => {
  router.push({ name: "Dashboard" });
};

</script>
