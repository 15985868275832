<template>
   <div class="card">
     <div class="card-header" v-if="hasTitle">
         <h3 class="card-title mb-0">Expedientes activos</h3>
     </div>
     <div class="card-body">
         <div class="table-responsive p-0" v-if="entities.length">
           <table class="table align-items-center mb-0">
             <thead>
             <tr>
               <th
                   class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
               >
                 Expediente
               </th>
               <th
                   class="
                          text-uppercase text-custom text-xxs text-center
                          font-weight-bolder
                          opacity-7
                        "
               >
                 Detalles
               </th>
               <td class="text-center"></td>
             </tr>
             </thead>

             <tbody>
             <tr v-for="item in entities" :key="item.id">
               <td>
                 <div class="d-flex px-2 py-1">
                   <div class="d-flex flex-column justify-content-center">
                     <h6 class="mb-0 text-sm">
                       <router-link
                           :to="{
                                  name: 'Detalle-Expedient',
                                  params: { id: item.id },
                                }"
                       >
                         <img src="@/assets/img/expedients-black.svg" alt=""> {{ item.code }} / {{ item.mainParent.fullName }} {{ item.secondaryParent.fullName}}
                       </router-link>
                     </h6>
                   </div>
                 </div>
               </td>
               <td class="text-center">
                 <router-link
                     class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                     :to="{
                            name: 'Detalle-Expedient',
                            params: { id: item.id },
                          }"
                 >  <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                   <View />
                 </el-icon>
                 </router-link>
               </td>
             </tr>
             </tbody>
           </table>
         </div>
         <el-alert  :closable="false" v-if="entities.length === 0 && !loading">
            <p>No hay expedientes actualmente o tienes acceso limitado
               Contacta con nuestros profesionales si crees que esto no es correcto.</p>
         </el-alert>
       </div>
   </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import {useStore} from "vuex";
//const searchName = ref("");
import {View} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";
//import { ref } from "vue";
const store = useStore();
const loading = ref(true)
defineProps({
  hasTitle: {
    type: Boolean,
    required: false,
    default: true
  }
})

const getExpedients = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("expedient/getEntities")
  spin.close()

};

onMounted(async () => {
  loading.value = true
  await getExpedients();
  loading.value = false
})

const entities = computed(
    () => store.state.expedient.entities.data ? store.state.expedient.entities.data.filter(item => {
                   return !item.banned || !item.banned.includes(store.getters['user/getUser'].id)
                }) : []

);
</script>

<style scoped>

</style>
