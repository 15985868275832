
export const genderList = [
        {
            'id': 'm',
            'name': 'Masculino'
        },
        {
            'id': 'f',
            'name': 'Femenino'
        },
        {
            'id': 'o',
            'name': 'Otro'
        }
    ]