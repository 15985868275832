<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">
                Nueva Reunión
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <meeting-form :entity="formModel" action="create" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import MeetingForm from "@/views/meeting/MeetingForm.vue";

const formModel = ref({
  expedient: null,
  administrator: null,
  parentUser: null,
  scheduledAt: null
});
</script>
