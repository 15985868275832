<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
           <card-header title="Usuarios" route="Crear-Usuario" buttonText="Nuevo usuario" />
            <div class="row">
              <div class="col-md-3 py-2">
                <el-input
                  clearable
                  type="text"
                  placeholder="Nombre"
                  v-model="searchName"
                  v-on:keyup.enter="handleSearch"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click="handleSearch"
                  class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase font-weight-bolder">
                      Nombre
                    </th>
                    <th class="text-center text-uppercase font-weight-bolder">
                      <div class="d-none d-lg-table-cell">Email</div>
                      <div class="d-lg-none d-table-cell">Datos</div>
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in users" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm wrap-balance">
                            <Avatar :value="item.photoPath" />
                            {{ item.firstname }}
                          </h6>
                        </div>
                      </div>
                      <div class="d-flex flex-column d-lg-none">
                        <router-link
                            :to="{
                          name: 'Actualizar-Usuario',
                          params: { id: item['id'] },
                        }"
                        >
                            <i
                                class="fa fa-pencil text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >Editar
                        </router-link>
                        <router-link
                            :to="{
                          name: 'Cambiar-Contraseña-Usuario',
                          params: { id: item['id'] },
                        }"
                        >
                          <a
                              class="btn btn-link text-dark px-3 mb-0"
                              href="javascript:;"
                          >
                            <i
                                class="fa fa-key text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >Cambiar Contraseña
                          </a>
                        </router-link>
                        <a
                            class="btn btn-link text-danger text-gradient px-3 mb-0"
                            href="javascript:;"
                            @click="handleDelete(item['id'])"
                        >
                          <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                        </a>
                      </div>
                    </td>
                    <td style="padding-left: 40px;">
                      <div class="d-lg-table-cell d-none"> {{ item.email }}</div>
                      <div class="ml-3 d-lg-none d-table-block"><b>Email: </b><br>{{ item.email }}</div>
                    </td>
                    <td class="d-none d-lg-table-cell text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Usuario',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link text-dark px-3 mb-0"
                          href="javascript:;"
                        >
                          <i
                            class="fa fa-pencil text-dark me-2"
                            aria-hidden="true"
                          ></i
                          >Editar
                        </a>
                      </router-link>
                      <router-link
                          :to="{
                          name: 'Cambiar-Contraseña-Usuario',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                            class="btn btn-link text-dark px-3 mb-0"
                            href="javascript:;"
                        >
                          <i
                              class="fa fa-key text-dark me-2"
                              aria-hidden="true"
                          ></i
                          >Cambiar Contraseña
                        </a>
                      </router-link>
                      <a
                        class="btn btn-link text-danger text-gradient px-3 mb-0"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        <i class="far fa-trash-alt me-2" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {ElLoading, ElMessageBox} from "element-plus";
import CardHeader from "@/components/CardHeader.vue";
import {Avatar} from "@element-plus/icons-vue";

const store = useStore();
const searchName = ref("");

    onMounted(() => {
      getUsers();
    });


    const handleSearch = () => {
      store.dispatch("user/getUsers", {
        firstname: searchName.value
      });
    };

const users = computed(() => store.state.user.entities);

const getUsers = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("user/getUsers").finally(()=> {
    spin.close()
  });
};

const deleteUser = async (user) => {
  try {
    await store.dispatch("user/deleteUser", user);
    await store.dispatch("user/getUsers");
  } catch (error) {
    console.log(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Estás seguro que deseas eliminar el usuario?")
    .then(() => {
      deleteUser(item);
    })
    .catch(() => {
      // catch error
    });
};

</script>
