import axios from '@/services/api';

export const getEntities= async ({ commit }, params) => {
    const response = await axios.get('/chat-message-reports', { params: params})
    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntityById = async ({ commit }, id) => {
    commit('setCurrentEntity', null);
    const response = await axios.get(`/chat-message-reports/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {
    return await axios.post('chat-message-reports', payload)
        .then((response) => response)
        .catch((e) => e.response);
};

export const edit = async (_, payload) => {
    return await axios.put('chat-message-reports/' + payload['id'], payload)
        .then((response) => response)
        .catch((e) => e.response);
};

/* export const removeImage = async (_, id) => {
    return await axios.delete('natural_space_images/' + id)
        .then((response) => response)
        .catch((e) => e.response);
}; */

export const analizeContent= async (_, comment) => {
   const data = {
        comment: { text: comment },
        languages: ['es'],
        requestedAttributes: {
            TOXICITY: {},
        },
    };
    const apiKey = 'AIzaSyD0jbz4qtuhM_O2TMln6hYWqCAReJ7Gtu0';
    const apiUrl = 'https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze';
    fetch(`${apiUrl}?key=${apiKey}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((result) => {
            // Handle the API response here
            console.log('Bienn');
            return result
        })
        .catch((error) => {
            console.error('Error:', error);
        });

};

export const deleteEntity= async ({ commit }, id) => {
    await axios.delete(`/chat-message-reports/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};
