<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div class=" d-flex justify-content-between">
              <h3 class="card-title mb-0">
                Expediente {{ currentEntity.code }}
              </h3>

              <div class="sub-line"></div>
              <div class="mt-2" v-if="isSuperAdmin()">
                <a href="" @click.prevent="exportToPdf()"
                   class="btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Exportar
                </a>
              </div>
            </div>
            <div>
              <div class="row col-12 mt-3">
                <router-link :to="{ name: target }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row" v-if="currentEntity">
               <div class="col-md-6">
                   <expedient-members :current-entity="currentEntity"  :id="currentEntity.id"/>
                   <expedient-administrators :administrators="currentEntity.administrators"
                                             expedient="currentEntity"/>
               </div>
               <div class="col-md-6">
                  <expedient-documents v-if="isParent()" :entity="currentEntity" />
                  <admin-expedient-documents v-if="isTotal()" :entity="currentEntity" />
               </div>
          </div>
          </div>
        </div>
        </div>
      </div>
    <div class="row" v-if="currentEntity">
      <div class="col-md-12">
          <expedients-events :current-entity="currentEntity" @update-entity="getCurrentExpedient"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import ExpedientDocuments from "@/views/expedient/ExpedientDocuments.vue";
import {isAdmin, isParent, isSuperAdmin, isTotal} from "@/services/authGuard";
import AdminExpedientDocuments from "@/views/expedient/AdminExpedientDocuments.vue";
import ExpedientMembers from "@/views/expedient/components/ExpedientMembers.vue";
import ExpedientAdministrators from "@/views/expedient/components/ExpedientAdministrators.vue";
import ExpedientsEvents from "@/views/expedient/components/ExpedientsEvents.vue";
import {ElLoading} from "element-plus";

const router = useRouter();
const route = useRoute();
const store = useStore();
const loading = ref(false)
const currentEntity = computed(() =>  {
  return store.state.expedient.currentEntity
});

const target = computed(() =>{
  return isParent() || isAdmin() ? 'Dashboard' : 'Expedients'
})


const getCurrentExpedient = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch('expedient/getEntityById', id).then(()=>{
     if(!currentEntity.value){
       router.push({'name': 'Dashboard'})
     }
  }).finally(()=> spin.close());
};

const exportToPdf = async () => {
  if(loading.value) {
    return
  }
  loading.value = true
  await store.dispatch('expedient/exportPdf', currentEntity.value.id) .then(response => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(response.data);
    a.download = currentEntity.value.expedient.code+'.pdf';
    document.body.appendChild(a);
    a.click();
    a.remove();
  })
      .catch(error => {
        console.error('Error loading PDF:', error);
      });
  loading.value = false
}
onMounted(async () => {
  await getCurrentExpedient(route.params.id)
});



</script>
