<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title mb-0">
                Acuerdo #"{{ currentEntity.id }}"
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Agreements' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm border-1  text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row">
               <div class="col-md-6">
                 <div class="card">
                    <div class="card-body">
                      <label class="form-control-label">
                        Expediente
                      </label>
                      <p><ExpedientItem :item="currentEntity.expedient" /></p>
                      <label class="form-control-label">
                        Autor
                      </label>
                      <p>{{ currentEntity.authorParent.fullName }}</p>
                      <label class="form-control-label">
                        Documento
                      </label>
                      <p> {{ currentEntity.document.title }} <a  target="_blank"
                              class="btn btn-link icon-primary px-3 mb-0 rounded text-primary text-start"
                              :href="imageUrl + currentEntity.document.filePath"
                      >
                        <el-icon :size="20"
                                 color="#fff">
                          <Download />
                        </el-icon>

                      </a></p>
                      <label class="form-control-label">
                        Respaldado por autor
                      </label>
                      <p><Yes :value="currentEntity.endorsedByAuthor" /></p>
                      <label class="form-control-label">
                        Respaldado por familiar
                      </label>
                      <p><Yes :value="currentEntity.endorsedByOtherParent" /></p>
                    </div>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted, ref} from "vue";
import { useRoute } from "vue-router";
import {ElLoading} from "element-plus";
import Yes from "@/components/Yes.vue";
import ExpedientItem from "@/components/ExpedientItem.vue";
import {Download} from "@element-plus/icons-vue";

const route = useRoute();
const store = useStore();
const imageUrl = ref(process.env.VUE_APP_URL+'/docs/')
const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
   await store.dispatch('agreement/getEntityById', id).finally(()=>spin.close());
};

const currentEntity = computed(() => store.state.agreement.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});
</script>
