<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="title" class="form-control-label">
          Título<span class="required">*</span>
        </label>

        <input
          id="title"
          v-model="formModel.title"
          :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />

        <p v-if="v.title.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.title.$errors[0].$message }}
          </small>
        </p>
        <label for="description" class="form-control-label">
          Descripción<span class="required">*</span>
        </label>

        <input
            id="description"
            v-model="formModel.description"
            :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
            type="text"
            placeholder=""
        />

        <p v-if="v.description.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.description.$errors[0].$message }}
          </small>
        </p>

        <label for="category" class="form-control-label w-100">
          Categoría<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.category"
            size="large"
            class="w-100"
        >
          <el-option
              v-for="selectItem in categories"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.name"
          />
        </el-select>
        <p v-if="v.category.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.category.$errors[0].$message }}
          </small>
        </p>

        <label for="expedient" class="form-control-label w-100">
          Expediente<span class="required">*</span>
        </label>
        <el-select
            v-model="formModel.expedient"
            size="large"
            class="w-100"
        >
          <el-option
              v-for="selectItem in expedients"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.code+' ' + selectItem.mainParent.fullName + '/'+ selectItem.secondaryParent.fullName"
          />
        </el-select>
        <p v-if="v.expedient.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.expedient.$errors[0].$message }}
          </small>
        </p>
        <template v-if="!isParent()">
        <label for="parentUser" class="form-control-label">
          Familiar<span class="required">*</span>
        </label>
        <select
            v-model="formModel.parentUser"
            id="parentUser"
            class="form-select"
        >
          <option
              v-for="selectItem in parents"
              :key="selectItem.id"
              :value="selectItem['@id']"
          >
            {{ selectItem.fullName }}
          </option>
        </select>
        <p v-if="v.parentUser.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.parentUser.$errors[0].$message }}
          </small>
        </p>
        </template>
    </div>
      <div class="col-md-6">
        <label for="document" class="form-control-label">
          Documento<span class="required">*</span>
        </label>
        <input
            type="file"
            id="video"
            @change="uploadFile"
            :class="[ 'form-control']"
            placeholder=""
        />
        <p v-if="v.file.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.file.$errors[0].$message }}
          </small>
        </p>
      </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import {isParent} from "@/services/authGuard";
import {clearErrors} from "@/utils/errorsUtils";
//import {languageList} from "@/utils/languages";

const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const fileList = ref([]);
const formModel = ref(props.entity|| {});
const parents = computed(() => store.state.parent.entities.data);
const expedients = computed(() => store.state.expedient.entities.data);
const categories = computed(() => store.state.documentCategory.entities.data);

const rules = computed(() => {
  return {
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    parentUser: !isParent() ? {
      required: helpers.withMessage("Este campo es obligatorio", required),
    } : {},
    category: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    title: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    file: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const documentId = ref(null);
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};
const uploadFile = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.readAsDataURL(files[i]);
    reader.onload = function(e) {
      formModel.value.file = e.target.result;
      // formModel.value.filePath = e.target.result;
    };
  }
}
const clearForm = () => {
  formModel.value.name = null
  formModel.value.document = null
};

onMounted(() => {
  store.dispatch("parent/getEntities");
  store.dispatch("expedient/getEntities");
  store.dispatch("documentCategory/getEntitiesNoPaginate");

  if (props.entity.document){
    documentId.value =  props.entity.document['@id'];

    fileList.value.push({
      name: props.entity.document.filePath,
      url: props.entity.document.imageUrl
    });

    formModel.value.document = props.entity.document['@id'];
  }
  if(props.entity.category){
    formModel.value.category = props.entity.category['@id'];
  }
  if(props.entity.expedient){
    formModel.value.expedient = props.entity.expedient['@id'];
  }else{
    formModel.value.expedient = props.entity['@id'];
  }
  if(props.entity.parentUser){
    formModel.value.parentUser = props.entity.parentUser['@id'];
  }
});

const handleCancel = () => {
  router.push({ name: 'Documents', params: {} });
};


const updateEntity = async (data) => {
  try {
    await store.dispatch("document/edit", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Documento editado satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: clearErrors(res),
          showConfirmButton: false,
          timer: 3000
        });
      }
    });

    clearForm()
    await router.push({ name: 'Documents', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  if(isParent()){
    data.parentUser = store.getters["user/getUser"]['@id']
  }
  try {
    await store.dispatch("document/add", data).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Documento creado satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
    });

    await router.push({ name: 'Documents', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

</script>
