<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-12">
        <label for="reason" class="form-control-label">
          Motivo<span class="required">*</span>
        </label>

        <input
          id="reason"
          v-model="formModel.reason"
          :class="[
                        'form-control',
                        v.reason.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />

        <p v-if="v.reason.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.reason.$errors[0].$message }}
          </small>
        </p>
        <label for="admin" class="form-control-label w-100">
          Profesional<span class="required">*</span>
        </label>
        <el-select   clearable
                     class="w-100"
                     v-model="formModel.administrator"
                     placeholder="Profesional"
                     size="large">
          <el-option
              v-for="selectItem in expedient.administrators"
              :key="selectItem.id"
              :value="selectItem['@id']"
              :label="selectItem.fullName"
          />
        </el-select>
        <p v-if="v.administrator.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.administrator.$errors[0].$message }}
          </small>
        </p>
        <el-row :gutter="25"  v-if="!isParent()">
          <el-col :xl="12" :xs="24" :md="24">
            <label for="date_time" class="form-control-label w-100 mt-3">
              Fecha
            </label>
            <el-date-picker
                v-model="formModel.requestedAt"
                type="datetime"
                value-format="YYYY-MM-DD H:m"
                placeholder="2023/08/16"
                :disabled-date="disabledDate"
                :shortcuts="shortcuts"
                :dayjs="Dayjs"
                :class="v.requestedAt.$errors.length >0 ? 'is-invalid' : ''"
            />
          </el-col>
          <el-col :xl="12" :xs="24" :md="24">
            <label for="date_time_end" class="form-control-label myDate w-100 mt-3">
              Fecha de fin
            </label>
            <el-date-picker
                v-model="formModel.scheduledEnd"
                type="datetime"
                value-format="YYYY-MM-DD H:m"
                placeholder="2023/08/16"
                :disabled-date="disabledDate"
                :shortcuts="shortcuts"
                :dayjs="Dayjs"
                :class="v.scheduledEnd.$errors.length >0 ? 'is-invalid' : ''"
            />
          </el-col>
        </el-row>
        <label for="reason" class="form-control-label mt-3 w-100">
          Descripción<span class="required">*</span>
        </label>

        <textarea
            :rows="5"
            id="description"
            v-model="formModel.description"
            :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
            placeholder=""
        />

        <p v-if="v.description.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.description.$errors[0].$message }}
          </small>
        </p>
        <label for="reason" class="form-control-label mt-3">
          Dinos dias y horas en las que solicitas la reunión<span class="required">*</span>
        </label>

        <textarea
            :rows="5"
            id="proposal"
            v-model="formModel.proposal"
            :class="[
                        'form-control',
                        v.proposal.$error ? 'is-invalid' : '',
                      ]"
            placeholder=""
        />

        <p v-if="v.proposal.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.proposal.$errors[0].$message }}
          </small>
        </p>
    </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click.prevent="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed,defineEmits} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import 'dayjs/locale/en';
import {isParent} from "@/services/authGuard";
const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },
  expedient: {
    type: Object,
    required: true
  },
  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {});
const emits = defineEmits(['closeForm','updateData'])
const rules = computed(() => {
  return {
    scheduledAt: {
    //  required: helpers.withMessage("Este campo es obligatorio", required),
    },
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    parentUser: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    administrator: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    reason: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    proposal: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    requestedAt: {
     // required: helpers.withMessage("Este campo es obligatorio", required),
    },
    scheduledEnd: {
     // required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();
  formModel.value.scheduledAt = formModel.value.requestedAt
  if (!v$.value.$invalid) {
    if (props.action === 'create'){

      formModel.value.requests = [
        {
          reason: formModel.value.reason,
          description: formModel.value.description,
          proposal: formModel.value.proposal,
          requestedAt: formModel.value.requestedAt,
          solicitor: formModel.value.parentUser
        }
      ]
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.name = null
};

const handleCancel = () => {
  router.push({ name: 'Meetings', params: {} });
};


const updateEntity = async (data) => {
  try {
    await store.dispatch("meeting/edit", data).then(() =>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud editada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    });
    emits('closeForm')
    emits('updateData')
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  try {
    await store.dispatch("meeting/add", data).then(async (res) => {
      if (res.status && (res.status === 200 || res.status === 201)) {
        emits('closeForm')
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Solicitud creada satisfactoriamente.',
          showConfirmButton: false,
          timer: 3000
        });
      } else {
        await Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Se ha producido un error.',
          showConfirmButton: false,
          timer: 3000
        });
      }

    });
    clearForm()

  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};
</script>
