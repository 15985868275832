<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h3 class="card-title text-white">Cambiar contraseña</h3>
          </div>
          <div class="card-body" v-if="currentUser">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="user_firstname" class="form-control-label">
                      Contraseña antigua
                    </label>
                    <input
                      id="user_firstname"
                      v-model="currentUser.oldPassword"
                      class="form-control"
                      type="password"
                      placeholder=""
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="user_lastname" class="form-control-label">
                        Contraseña nueva
                      </label>
                      <input
                        v-model="currentUser.newPassword"
                        id="user_lastname"
                        class="form-control"
                        type="password"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="user_lastname" class="form-control-label">
                        Repetir Contraseña nueva
                      </label>
                      <input
                        v-model="currentUser.newRetypedPassword"
                        id="user_lastname"
                        class="form-control"
                        type="password"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Cambiar contraseña
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import Swal from "sweetalert2";


    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const getCurrentUser = async (id) => {
      return await store.dispatch("user/getCurrentUser", id);
    };

    onMounted(getCurrentUser(route.params.id));

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);


    const handleSubmit = () => {

        if(currentUser.value.newRetypedPassword !== currentUser.value.newPassword){
            ElMessage.error("Las contraseñas no coinciden");
          return;
        }
      changePassword(currentUser.value);
    };

    const changePassword = async (user) => {
        await store.dispatch("user/changePassword", user).then(async (res) => {
          if(res.status === 200){
            await Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Sus contraseña se ha cambiado.',
              showConfirmButton: false,
              timer: 3000
            })
            await router.push({ name: "Dashboard" });
          }else{
            console.log(res)
            await Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Los datos introducidos no son válidos',
              showConfirmButton: false,
              timer: 3000
            })
          }


        }).catch(async (error) => {
          console.log('Error ' + error)
          await Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ha ocurrido un error al cambiar la contraseña',
            showConfirmButton: false,
            timer: 3000
          })
        })
      }

    const handleCancel = () => {
      router.push({ name: "Dashboard" });
    };

</script>
