<template>
  <div class="row mt-3">
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            Expedientes
          </h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 col-12 py-2">
              <el-input
                  clearable
                  type="text"
                  placeholder="Buscar"
                  v-model="searchName"
              />
            </div>
          </div>
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
              <tr>
                <th class="text-uppercase text-custom text-xxs font-weight-bolder opacity-7">
                  Expediente
                </th>
                <th class="text-uppercase text-custom text-xxs text-center font-weight-bolder opacity-7">
                </th>
              </tr>
              </thead>

              <tbody>
              <tr  @click="selectExpedient(item)" v-for="item in entities" :key="item.id"  :class="selectedExpedient && (item.id === selectedExpedient.id) ? 'bg-primary color-white' : ''" class="cursor-pointer">
                <td style="padding-left: 0!important;">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm wrap-balance">
                        <img v-if="!selectedExpedient" src="@/assets/img/expedients-black.svg" alt="">
                        <img v-else src="@/assets/img/expedients.svg" alt="">
                        {{ item.expedient.code }} -- {{ item.expedient.mainParent.fullName }} / {{ item.expedient.secondaryParent.fullName }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <button
                      class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                  > <el-icon :size="25"
                             class="mr-2"
                             color="#fff">
                    <View />
                  </el-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <expedients-events v-if="selectedExpedient"
                         :current-entity="selectedExpedient?.expedient"
                         :events="events"
                         @update-entity="getCurrentExpedient"/>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted,defineProps } from "vue";
import { useStore } from "vuex";
import { ref } from "vue";
import {View} from "@element-plus/icons-vue";
import ExpedientsEvents from "@/views/event/ExpedientsEvents.vue";
import {ElLoading} from "element-plus";
// eslint-disable-next-line no-unused-vars
import moment from "moment/moment";



const store = useStore();
const searchName = ref("");
const selectedExpedient = ref()

defineProps({
  hasTitle: {
    type: Boolean,
    required: false,
    default: true
  }
})

/*watch(searchName, async () => {
  handleSearch()
})*/

const getCurrentExpedient = async () => {
  if(selectedExpedient.value) {
    await store.dispatch('administratorExpedient/getEntityById', selectedExpedient.value.id)
  }
}
const getExpedients = async () => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("administratorExpedient/getEntities", {
    "administrator.id": store.getters['user/getUser'].id
  });
  spin.close()
};

onMounted(()=> {
  getExpedients();
  if(store.state.administratorExpedient.entities.data && store.state.administratorExpedient.entities.data.length >0){
    selectedExpedient.value = store.state.administratorExpedient.entities.data[0]
  }

});

const events = computed(() => {
  if(selectedExpedient.value){
    let events = store.state.event.entities.data
    let adminEvents = store.state.administratorEvent.entities.data
    let res = []
    let ids = []
    if(events){
      events.map(item=> {
        if(!ids.includes(item.id)){
          res.push(item)
          ids.push(item.id)
          if(item.freq){
            let repeated = repeatEvent(item)
            res.push(repeated)
          }
        }
      })
    }
    if(adminEvents) {
      adminEvents.map(item => {
        if(!ids.includes(item.id)){
          res.push(item)
          ids.push(item.id)
          if(item.freq){
            let repeated = repeatEvent(item)
            res.push(repeated)
          }
        }

      })
    }


    return res
  }

 return []
})
const entities = computed(
    () => store.state.administratorExpedient.entities.data
);
const repeatEvent = (item) => {
    let newItem = {...item};
    newItem.time = {...item.time}
    if (item.freq === 4) {
      const momentCopy = moment(newItem.time.start);
      momentCopy.add(1, 'months');
      newItem.time.start = momentCopy.format('YYYY-MM-DD hh:mm')
      newItem.time.end = momentCopy.format('YYYY-MM-DD hh:mm')

    }
    if (item.freq === 3) {
      const momentCopy = moment(newItem.time.start);
      momentCopy.add(2, 'weeks');
      newItem.time.start = momentCopy.format('YYYY-MM-DD hh:mm')
      newItem.time.end = momentCopy.format('YYYY-MM-DD hh:mm')

    }
    if (item.freq === 2) {
      const momentCopy = moment(newItem.time.start);
      momentCopy.add(1, 'weeks');
      newItem.time.start = momentCopy.format('YYYY-MM-DD hh:mm')
      newItem.time.end = momentCopy.format('YYYY-MM-DD hh:mm')

    }
    if (item.freq === 1) {
      const momentCopy = moment(newItem.time.start);
      momentCopy.add(1, 'days');
      newItem.time.start = momentCopy.format('YYYY-MM-DD hh:mm')
      newItem.time.end = momentCopy.format('YYYY-MM-DD hh:mm')

    }

    return newItem

}
const selectExpedient = async item => {
  selectedExpedient.value = item
  await store.dispatch('administratorExpedient/getEntityById', item.id)
  await store.dispatch('eventCategory/getEntities')
  await store.dispatch('administratorEvent/getEntities', {
    'expedient.id': item.expedient.id
  })
  await store.dispatch( 'event/getEntities', {
    'expedient.id': item.expedient.id
  })
}
</script>

<style scoped lang="scss">
.color-white {
  color: white!important;
  cursor: pointer!important;
  a, i, h6 {
    color: white!important;
  }
}
</style>