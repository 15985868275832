<template>

  <div  v-if="currentUser" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">Editar usuario</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
                <user-form :entity="currentUser" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import UserForm from "./UserForm";
import {ElLoading} from "element-plus";

const store = useStore();
const route = useRoute();


    const getCurrentUser = async (id) => {
      const spin = ElLoading.service({
        lock: true,
        text: '',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      await store.dispatch("user/getCurrentUser", id).finally(()=>spin.close())
    };

    onMounted(()=>{
      getCurrentUser(route.params.id)
    });

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);


</script>
