<template>
   <div  v-if="currentEntity" class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title text-primary mb-0">
                Editar Documento
              </h3>

              <div class="sub-line"></div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm mx-60">
              <document-form :entity="currentEntity" action="update" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import DocumentForm from "./DocumentForm.vue";
import {ElLoading} from "element-plus";

const store = useStore();
const route = useRoute();

const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch("document/getEntityById", id).finally(()=>spin.close())
};

const currentEntity = computed(() => {
  if (!store.state.document.currentEntity) {
    return null;
  }

  return store.state.document.currentEntity;

});

onMounted(()=>{
  getCurrentEntity( route.params.id);
});
</script>
