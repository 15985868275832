<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title mb-0">
                {{ currentEntity.title }}
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Events' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row">
               <div class="col-md-6">
                 <div class="card">
                    <div class="card-body">
                      <label for="name" class="form-control-label">
                        Título
                      </label>
                     <p>{{ currentEntity.title }}</p>
                      <label for="name" class="form-control-label">
                        Descripción
                      </label>
                      <p>{{ currentEntity.description }}</p>
                      <label for="name" class="form-control-label">
                        Categoría
                      </label>
                      <p>{{ currentEntity.category.name }}</p>
                      <label for="name" class="form-control-label">
                        Lugar
                      </label>
                      <p>{{ currentEntity.location }}</p>
                      <label for="name" class="form-control-label">
                        Fecha de inicio
                      </label>
                      <p>{{ formatDate(currentEntity.dateTime) }}</p>
                      <label for="name" class="form-control-label">
                        Fecha de fin
                      </label>
                      <p>{{ formatDate(currentEntity.endDateTime) }}</p>
                    </div>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted} from "vue";
import { useRoute } from "vue-router";
import moment from "moment";

const route = useRoute();
const store = useStore();

const getCurrentEntity = async (id) => {
  return await store.dispatch('event/getEntityById', id);
};

const currentEntity = computed(() => store.state.event.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});

const formatDate = (value) => {
  if (value) {
    return moment((value)).format('MM/DD/YYYY HH:mm')
  }

  return ''
};


</script>
