<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="reason" class="form-control-label">
          Motivo<span class="required">*</span>
        </label>

        <input
          id="reason"
          v-model="formModel.reason"
          :class="[
                        'form-control',
                        v.reason.$error ? 'is-invalid' : '',
                      ]"
          type="text"
          placeholder=""
        />

        <p v-if="v.reason.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.reason.$errors[0].$message }}
          </small>
        </p>
        <div class="col-lg-6">
          <label for="date_time" class="form-control-label myDate">
            Fecha
          </label>
          <el-date-picker
              v-model="formModel.requestedAt"
              type="datetime"
              value-format="YYYY-MM-DD H:m"
              placeholder="2023/08/16"
              :disabled-date="disabledDate"
              :shortcuts="shortcuts"
              :dayjs="Dayjs"
              :class="v.requestedAt.$errors.length >0 ? 'is-invalid' : ''"
          />
        </div>
        <div class="col-lg-6">
          <label for="date_time_end" class="form-control-label myDate">
            Fecha de fin
          </label>
          <el-date-picker
              v-model="formModel.scheduledEnd"
              type="datetime"
              value-format="YYYY-MM-DD H:m"
              placeholder="2023/08/16"
              :disabled-date="disabledDate"
              :shortcuts="shortcuts"
              :dayjs="Dayjs"
              :class="v.scheduledEnd.$errors.length >0 ? 'is-invalid' : ''"
          />
        </div>
        <label for="proposal" class="form-control-label mt-3">
          Propuesta de fecha y hora<span class="required">*</span>
        </label>

        <textarea
            id="proposal"
            v-model="formModel.proposal"
            :class="[
                        'form-control',
                        v.proposal.$error ? 'is-invalid' : '',
                      ]"
            placeholder=""
        />

        <p v-if="v.proposal.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.proposal.$errors[0].$message }}
          </small>
        </p>
        <label for="reason" class="form-control-label mt-3">
          Descripción<span class="required">*</span>
        </label>

        <textarea
            id="description"
            v-model="formModel.description"
            :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
            placeholder=""
        />

        <p v-if="v.description.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.description.$errors[0].$message }}
          </small>
        </p>
    </div>
      <div class="col-md-6">

      </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import 'dayjs/locale/en';
const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});

const formModel = ref(props.entity|| {});

const rules = computed(() => {
  return {
    reason: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    description: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    requestedAt: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    scheduledEnd: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    proposal: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }
  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};

const clearForm = () => {
  formModel.value.name = null
};

const handleCancel = () => {
  router.push({ name: 'Requests', params: {} });
};


const updateEntity = async (data) => {
  try {
    await store.dispatch("request/edit", data).then(() =>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud editada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    });

    clearForm()
    await router.push({ name: 'Requests', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  try {
    await store.dispatch("request/add", data).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud creada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
    });

    await router.push({ name: 'Requests', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};
</script>
<style lang="scss" scoped>
  .myDate {
    margin-right: 5px;
    margin-top: 5px;
    display: block;
  }

</style>