<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div>
              <h3 class="card-title mb-0">Nuevo Super Administrador</h3>
              <div class="sub-line"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <user-form :entity="newUser" type="super" action="create" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import UserForm from "./UserForm";


    const newUser = ref({
      email: null,
      firstName: null,
      lastName: null,
      password: null,
      retypePassword: null,
      enabled: true
    });

</script>
