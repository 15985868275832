<template>
      <div class="card" v-if="currentEntity">
        <div class="card-header">
          <h3 class="title flex flex-row justify-between mb-0">
            Menores
            <button class="btn btn-primary btn-sm border-1 text-white mb-0" @click="showChildForm = true">Crear nuevo</button>
          </h3>
        </div>
        <div class="card-body" v-if="currentEntity">
          <ul class="list-unstyled">
            <li class="col-12 row mb-2" v-for="child in currentEntity.children" :key="child.id">
              <div class="col-md-9">
                <Avatar :value="child.photoPath" />
                 {{ child.fullName }}
              </div>
              <div class="col-md-3">
                <el-row :gap="2">
                  <el-col :span="8">
                    <router-link v-if="isAdmin() || isSuperAdmin()" class="btn btn-link text-dark mb-0 rounded icon-primary"
                                 :to="{
                            name: 'Actualizar-Child',
                            params: { id: child.id },
                          }"
                    >
                      <el-icon :size="25" class="mr-2" color="#fff">
                        <View />
                      </el-icon>
                    </router-link>
                  </el-col>
                  <el-col :span="8">
                    <router-link v-if="isParent() && store.state.user.accessChildInfo"
                                 class="btn btn-link text-dark mb-0 rounded icon-primary"
                                 :to="{
                                name: 'Detalle-Child',
                                params: { id: child.id }
                             }"
                    >
                      <el-icon :size="25" class="mr-2" color="#fff">
                        <View />
                      </el-icon>
                    </router-link>
                  </el-col>
                  <el-col :span="8">
                    <a v-if="isAdmin() || isSuperAdmin()"
                       class="btn btn-link text-danger text-gradient mb-0 rounded icon-danger"
                       href="javascript:;"
                       @click="handleDelete(child.id)"
                    >
                      <el-icon :size="25"
                               class="mr-2"
                               color="#fff">
                        <Delete />
                      </el-icon>
                    </a>
                  </el-col>
                </el-row>

              </div>
            </li>
          </ul>
        </div>
      </div>
  <div class="card mt-3" v-if="currentEntity">
    <div class="card-header">
      <h3 class="title flex flex-row justify-between mb-0">
        Familiares
      <!--  <button class="btn btn-primary btn-sm border-1 text-white mb-0" @click="showParentForm = true">Crear nuevo</button>-->
      </h3>
    </div>
    <div class="card-body" v-if="currentEntity">
      <ul class="list-unstyled">
        <li class="col-12 row mb-3">
          <div class="col-md-9">
            <Avatar :value="currentEntity.mainParent.photoPath" />
            {{ currentEntity.mainParent.fullName }}
          </div>
          <div class="col-md-3">
            <el-row :gap="2">
              <el-col :span="8">
                <router-link v-if="(isAdmin() || isSuperAdmin()) || (currentEntity.mainParent.id === store.getters['user/getUser'].id)"
                             class="btn btn-link text-dark mb-0 rounded icon-primary"
                             title="Detalles"
                             :to="{
                            name: 'Actualizar-Parent',
                            params: { id: currentEntity.mainParent.id },
                          }"
                >
                  <el-icon :size="25" color="#fff">
                    <View />
                  </el-icon>
                </router-link>
              </el-col>
              <el-col :span="8">
                <a v-if="isAdmin()"
                   title="Acceso a expediente"
                   :class="!currentEntity.banned.includes(currentEntity.mainParent.id) ? 'text-warning icon-warning' : 'text-success icon-success'"
                   class="btn btn-link text-gradient mb-0 rounded "
                   href="javascript:;"
                   @click="handleBanParent(currentEntity.mainParent.id)"
                >
                  <el-icon :size="25"
                           color="#fff">
                    <Lock v-if="!currentEntity.banned.includes(currentEntity.mainParent.id)"/>
                    <Unlock v-else/>
                  </el-icon>
                </a>
              </el-col>
              <el-col :span="8">
                <a v-if="isAdmin()"
                   title="Eliminar"
                   class="btn btn-link text-danger text-gradient mb-0 rounded icon-danger"
                   href="javascript:;"
                   @click="handleDeleteParent(currentEntity.mainParent.id)"
                >
                  <el-icon :size="25"
                           color="#fff">
                    <Delete />
                  </el-icon>
                </a>
              </el-col>
            </el-row>
          </div>
        </li>
        <li class="col-12 row mt-2">
          <div class="col-md-9">
            <Avatar :value="currentEntity.secondaryParent.photoPath" />
            {{ currentEntity.secondaryParent.fullName }}
          </div>
          <div class="col-md-3">
            <el-row>
              <el-col :span="8">
                 <router-link v-if="(isAdmin() || isSuperAdmin()) || (currentEntity.secondaryParent.id === store.getters['user/getUser'].id)"
                              class="btn btn-link text-dark mb-0 rounded icon-primary"
                              title="Detalles"
                              :to="{
                            name: 'Actualizar-Parent',
                            params: { id: currentEntity.secondaryParent.id },
                          }"
                 >
                   <el-icon :size="25" color="#fff">
                     <View />
                   </el-icon>
                 </router-link>
               </el-col>
              <el-col :span="8">
                <a v-if="isAdmin()"
                   title="Acceso a expediente"
                   :class="!currentEntity.banned.includes(currentEntity.secondaryParent.id) ? 'text-warning icon-warning' : 'text-success icon-success'"
                   class="btn btn-link text-gradient mb-0 rounded"
                   href="javascript:;"
                   @click="handleBanParent(currentEntity.secondaryParent.id)"
                >
                  <el-icon :size="25"
                           color="#fff">
                    <Lock v-if="!currentEntity.banned.includes(currentEntity.secondaryParent.id)"/>
                    <Unlock v-else/>
                  </el-icon>
                </a>
              </el-col>
              <el-col :span="8">
                <a v-if="isAdmin()"
                   title="Eliminar"
                   class="btn btn-link text-danger text-gradient mb-0 rounded icon-danger"
                   href="javascript:;"
                   @click="handleDeleteParent(currentEntity.mainParent.id)"
                >
                  <el-icon :size="25"
                           color="#fff">
                    <Delete />
                  </el-icon>
                </a>
              </el-col>
            </el-row>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <el-dialog class="dd" v-model="showChildForm" title="Crear Menor">
      <ChildForm action="create"
                 @close-from="closeChildForm"
                 :entity="currentEntity"
                 :id="id"/>
  </el-dialog>
  <el-dialog class="dd" v-model="showParentForm" title="Crear Familiar">
    <ParentForm action="create" @close-form="closeParentForm"/>
  </el-dialog>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {Delete, Lock, Unlock, View} from "@element-plus/icons-vue";
import {ElMessageBox} from "element-plus";
import {useStore} from "vuex";
import ChildForm from "@/views/dashboard/ChildForm.vue";
import ParentForm from "@/views/dashboard/ParentForm.vue";
import {isAdmin, isParent, isSuperAdmin} from "@/services/authGuard";
import Avatar from "@/components/Avatar.vue";

const store = useStore()
const props = defineProps({
  currentEntity: {
    type: Object,
    required: false
  },
  id: {
    type: Number,
    required: true
  }
});
const showParentForm = ref(false)
const showChildForm = ref(false)
const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el menor?")
      .then(() => {
        deleteChild(item);
      })
      .catch((error) => {
        console.error(error);
      });
};
const handleDeleteParent = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar el Familiar?")
      .then(() => {
        deleteParent(item);
      })
      .catch((error) => {
        console.error(error);
      });
};
const handleBanParent = (item) => {
  let action = !props.currentEntity.banned.includes(item) ? 'habilitar' : 'bloquear'
  ElMessageBox.confirm("¿Está seguro que deseas "+ action + " el acceso al expediente de este Familiar?")
      .then(() => {
        banParent(item);
      })
      .catch((error) => {
        console.error(error);
      });
};
const deleteChild = async (id) => {
  try {
    await store.dispatch("child/deleteEntity", id);
    await updateExpedient()
  } catch (error) {
    console.error(error);
  }
};
const deleteParent = async (id) => {
  try {
    await store.dispatch("parent/deleteEntity", id);
    await updateExpedient()
  } catch (error) {
    console.error(error);
  }
};
const banParent = async (id) => {
  try {
    let payload = {
      id: id,
      exp: props.currentEntity.id
    }
    await store.dispatch("parent/ban", payload);
    await updateExpedient()
  } catch (error) {
    console.error(error);
  }
};
const updateExpedient = async () => {
    await store.dispatch('expedient/getEntityById', props.id);
}
const closeChildForm = async () => {
  showChildForm.value = false
  await updateExpedient()

}
const closeParentForm = () =>{
   showParentForm.value = false
}
</script>

<style scoped>

</style>