import axios from '@/services/api';


export const getEntities = async ({ commit }, payload) => {

    const response = await axios.get('/notifications', payload)

    const { data } = response;

    try {
        if (data) {
            commit('setEntities', {data: data.data["hydra:member"]});
            commit('setPagination',  data.pagination);
        }

    } catch (e) {
        console.log(e);
    }

};

export const getEntityById = async ({ commit }, id) => {

    const response = await axios.get(`/notifications/${id}`)

    const { data } = response;

    try {
        if (data) {
            commit('setCurrentEntity', data);
        }

    } catch (e) {
        console.log(e);
    }
}

export const add = async (_, payload) => {

    return await axios.post("notifications", payload)
        .then((data) => {
            console.log(data);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    return await axios.put("notifications/" + payload['id'], payload)
        .then((response) => response.json())
        .then((data) => {
            commit('setEntities', data.data);
        })
        .catch((e) => {
            console.log(e);
        });
};

export const deleteItem= async ({ commit }, id) => {

    await axios.delete(`/notifications/${id}`)

    try {
        commit('setLoading', false);
    } catch (e) {
        console.log(e);
    }
};

