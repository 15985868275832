<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title text-primary mb-0">
                Sentencia #"{{ currentEntity.id }}"
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Sentences' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row">
               <div class="col-md-6">
                 <div class="card">
                    <div class="card-body">
                      <label for="name" class="form-control-label">
                        Nombre
                      </label>
                    </div>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted} from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();

const getCurrentEntity = async (id) => {
  return await store.dispatch('sentence/getEntityById', id);
};

const currentEntity = computed(() => store.state.sentence.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});
</script>
