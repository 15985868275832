<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-6">
        <label for="expedient" class="form-control-label">
          Expediente<span class="required">*</span>
        </label>
        <select
            v-model="formModel.expedient"
            id="expedient"
            class="form-select"
        >
          <option
              v-for="selectItem in expedients"
              :key="selectItem.id"
              :value="selectItem['@id']"
          >
            {{ selectItem.child.firstName }}  {{ selectItem.child.lastName }}
          </option>
        </select>
        <p v-if="v.expedient.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.expedient.$errors[0].$message }}
          </small>
        </p>
        <label for="content" class="form-control-label">
          Contenido<span class="required">*</span>
        </label>

        <textarea
            rows="10"
            id="content"
            v-model="formModel.content"
            :class="[
                        'form-control',
                        v.content.$error ? 'is-invalid' : '',
                      ]"
            placeholder=""
        />

        <p v-if="v.content.$error" class="invalid-feedback mb-0">
          <small>
            {{ v.content.$errors[0].$message }}
          </small>
        </p>
    </div>
    <div class="mt-3">
      <button
        type="submit"
        class="float-end btn btn-primary text-white bg-blue-600 rounded-lg border border-primary hover:bg-blue-900">
        {{ buttonText }}
      </button>

      <button
        class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
        @click="handleCancel"
      >
        Cancelar
      </button>
    </div>
    </div>
  </form>
</template>

<script setup>

import {ref, defineProps, computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();

const props = defineProps({
  entity: {
    type: Object,
    required: false
  },

  action: {
    type: String,
    required: true
  }
});
const expedients = computed(() => store.state.expedient.entities.data);
const formModel = ref(props.entity|| {});

const rules = computed(() => {
  return {
    content: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    },
    expedient: {
      required: helpers.withMessage("Este campo es obligatorio", required),
    }


  };
});

const v$ = useVuelidate(rules, formModel);
const v = v$.value;
const buttonText = computed(
  () => props.action === 'create' ? 'Crear' : 'Guardar cambios'
);

const handleSubmit = () => {
  v$.value.$validate();

  if (!v$.value.$invalid) {
    if (props.action === 'create'){
      createEntity( formModel.value);
    } else {
      updateEntity( formModel.value);
    }
  }
};
onMounted(() => {
  store.dispatch("expedient/getEntities");
})
const clearForm = () => {
  formModel.value.name = null
};

const handleCancel = () => {
  router.push({ name: 'Sentences', params: {} });
};


const updateEntity = async (data) => {
  try {
    await store.dispatch("sentence/edit", data).then(() =>{
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Sentencia editada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      })
    });

    clearForm()
    await router.push({ name: 'Sentences', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

const createEntity = async (data) => {
  try {
    await store.dispatch("sentence/add", data).then(() => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Sentencia creada satisfactoriamente.',
        showConfirmButton: false,
        timer: 3000
      });
    });

    await router.push({ name: 'Sentences', params: {} });
  } catch (error) {
    await Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: 'Se ha producido un error.',
      showConfirmButton: false,
      timer: 3000
    });

    console.log(error);
  }
};

</script>
