
export const setEntities = (state, provinces) => {
    state.entities = provinces
    state.loading = false
}

export const setPagination = (state, pagination) => {
    state.pagination = pagination;
    state.loading = false;
};

export const setCurrentEntity = (state, province) => {
    state.currentEntity = province
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}
