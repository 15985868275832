
export const frequenciesList = [
    {
        'id': null,
        'name': 'Una sola vez'
    },
    {
        'id': 1,
        'name': 'Diario'
    },
    {
        'id': 2,
        'name': 'Semanal'
    },
    {
        'id': 3,
        'name': 'Quincenal'
    },
    {
        'id': 4,
        'name': 'Mensual'
    }
    ]