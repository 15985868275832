<template>
  <div v-if="pagination" class="mt-5">
    <ul class="pagination">
      <li class="page-item" v-if="pagination.current && pagination.current !== pagination.first && pagination.current > 1"><a class="page-link btt" @click.prevent="prevPage">&lt;&lt;</a></li>
      <li class="page-item" v-else><a class="page-link btt" disabled>&lt;&lt;</a></li>
      <li class="page-item"> <span class="page-link">{{ pagination.current }} / {{  pagination.last }}</span></li>
      <li class="page-item" v-if="pagination.current && pagination.current !== pagination.last"> <a class="page-link btt" @click.prevent="nextPage">&gt;&gt;</a></li>
      <li class="page-item" v-else><a class="page-link btt" disabled>&gt;&gt;</a></li>
      <li class="page-item"> <span class="page-link total">Total: {{pagination.totalItems}} </span></li>
    </ul>
  </div>
</template>

<script setup>
import {defineProps} from "vue";
import {useStore} from "vuex";
const props = defineProps({
  pagination: {
    type: Object,
    required: false
  },
  entity: {
    type: String,
    required: false
  },
})
const store = useStore();
const prevPage = () => {
  store.dispatch(props.entity+"/getEntities", {
    page: parseInt(props.pagination.previous)
  });
};
const nextPage = () => {
  store.dispatch(props.entity+"/getEntities", {
    page: parseInt(props.pagination.next)
  });
};
</script>

<style scoped lang="scss">
  .pagination {
    margin-left: 25px;
    .page-item {
        .page-link {
          border-radius: 4px!important;
        }

    }
  }
</style>