<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title mb-0">
                Menor #"{{ currentEntity.fullName }}"
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link  v-if="isAdmin() || isSuperAdmin()" :to="{ name: 'Children' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
                 <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title mb-0">Datos personales</h3>
                            </div>
                            <div class="card-body">
                              <div>
                                <Avatar style="max-width: 100px;" :value="currentEntity.photoPath" :size="45" />
                                <label>{{ currentEntity.fullName }}</label>
                              </div>
                              <label  v-if="isAdmin() || isSuperAdmin()" for="dni" class="form-control-label">
                                DNI
                              </label>
                              <p v-if="isAdmin() || isSuperAdmin()">{{ currentEntity.personalInfo.dni }}</p>
                              <label for="gender" class="form-control-label w-100">
                                Género
                              </label>
                              <Gender :value="currentEntity.personalInfo.gender" />
                              <label for="date_of_birth" class="form-control-label w-100">
                                Fecha de nacimiento
                              </label>
                               <BirthDay :value="currentEntity.personalInfo.dateOfBirth" />

                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="isAdmin() || isSuperAdmin()">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title mb-0">Dirección</h3>
                            </div>
                            <div class="card-body">
                              <label for="address_street" class="form-control-label">
                                Calle
                              </label>
                              <p>{{ currentEntity.address.street }}</p>

                              <label for="address_number" class="form-control-label">
                                Número
                              </label>
                              <p>{{ currentEntity.address.number }}</p>

                              <label for="address_door" class="form-control-label">
                                Puerta
                              </label>
                              <p>{{ currentEntity.address.door }}</p>

                              <label for="address_floor" class="form-control-label">
                                Piso
                              </label>
                              <p>{{ currentEntity.address.floor }}</p>

                              <label for="address_ladder" class="form-control-label">
                                Escalera
                              </label>
                              <p>{{ currentEntity.address.ladder }}</p>

                              <label for="address_zip_code" class="form-control-label">
                                Código postal
                              </label>

                              <p>{{ currentEntity.address.zipCode }}</p>
                                  <label class="form-control-label w-100">
                                    Comunidad
                                  </label>
                                  <p>{{ currentEntity.province.community.name }}</p>
                                    <label for="city" class="form-control-label w-100">
                                      Ciudad
                                    </label>
                                  <p>{{ currentEntity.province.name }}</p>
                                    <label  class="form-control-label w-100">
                                      Provincia
                                    </label>
                                  <p>{{ currentEntity.province.name }}</p>
                                    <label class="form-control-label w-100">
                                      Localidad
                                    </label>
                                  <p>{{ currentEntity.locality }}</p>

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted} from "vue";
import { useRoute } from "vue-router";
import {Avatar} from "@element-plus/icons-vue";
import BirthDay from "@/components/BirthDay.vue";
import Gender from "@/components/Gender.vue";
import {isAdmin, isSuperAdmin} from "@/services/authGuard";

const route = useRoute();
const store = useStore();

const getCurrentEntity = async (id) => {
  return await store.dispatch('child/getEntityById', id);
};

const currentEntity = computed(() => store.state.child.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});
</script>
