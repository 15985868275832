export const setEntities = (state, entities) => {
    state.entities = entities;
}

export const setPagination = (state, pagination) => {
    state.pagination = pagination;
    state.loading = false;
};

export const setCurrentUser = (state, user) => {
    state.currentUser = user;
    state.loading = false
}

export const setUserRole = ( state, role ) => {
    state.userRole = role
}

export const setAccessChildInfo = ( state, value ) => {
    state.accessChildInfo = value
}

export const setUser = (state, user) => {
    state.user = user;
    sessionStorage.setItem('user',JSON.stringify(user))
    state.loading = false
}

export const setTotals = (state, totals) => {
    state.totals = totals;
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setErrorMessage = (state, errorMessage) => {
    state.errorMessage = errorMessage
}