export const toggleConfigurator = (state) => {
    state.showConfig = !state.showConfig;
}
export const navbarMinimizeFromMain = (state) => {
    if(window.screen.width > 776){
        return
    }
    const sidenav_show = document.querySelector(".g-sidenav-show");
    if (!sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
    }
}

export const navbarMinimize = (state) => {
    const sidenav_show = document.querySelector(".g-sidenav-show");
    if (sidenav_show.classList.contains("g-sidenav-hidden") || sidenav_show.classList.contains("initial") ) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.remove("initial");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
    } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
    }
}
export const sidebarType = (state, payload) => {
    state.isTransparent = payload;
}
export const cardBackground = (state, payload) => {
    state.color = payload;
}
export const navbarFixed = (state) => {
    if (state.isNavFixed === false) {
        state.isNavFixed = true;
    } else {
        state.isNavFixed = false;
    }
}
