

export const getCurrentUser = (state) => {
    return state.currentUser
}

export const getUser = (state) => {
    if(state.user) return state.user
    if(sessionStorage.getItem('user')){
        return JSON.parse(sessionStorage.getItem('user'))
    }
    return state.user
}


export const getErrorMessage = (state) => {
    return state.errorMessage
}