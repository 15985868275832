import store from '../store';

export const isAuthenticatedGuard =  (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]) {
        next();
    } else {
       store.dispatch('auth/logout');
        next({ name: 'Login' });
    }
}
export const isSuperAdmin = () => {
    if(typeof store === 'undefined'){
        return sessionStorage.getItem('userRole') === 'ROLE_SUPER_ADMIN'
    }
    let role = store.state.user.userRole ?? store.getters["auth/getRole"]
    return  role === 'ROLE_SUPER_ADMIN';
}

export const isParent = () => {
    if(typeof store === 'undefined'){
        return sessionStorage.getItem('userRole') === 'ROLE_USER'
    }
    let role = store.state.user.userRole ?? store.getters["auth/getRole"]
    return role === 'ROLE_USER';
}

export const isAdmin = () => {
    if(typeof store === 'undefined'){
        return sessionStorage.getItem('userRole') === 'ROLE_ADMIN'
    }
    let role = store.state.user.userRole ?? store.getters["auth/getRole"]
    return role === 'ROLE_ADMIN';
}

export const isTotal = () => {
    if(typeof store === 'undefined'){
        return sessionStorage.getItem('userRole') === 'ROLE_ADMIN'
    }
    let role = store.state.user.userRole ?? store.getters["auth/getRole"]
    return role === 'ROLE_ADMIN' || role === 'ROLE_SUPER_ADMIN';
}

export const conditionalNext = function(condition) {
    return (to, from, next) => {
        if (condition) {
            next();
            return
        }
        next({ name: 'Login' });
    }
}

