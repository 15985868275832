<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="">
                <h3 class="card-title mb-0">
                  Categorías de Documentos
                </h3>

                <div class="sub-line"></div>
              </div>

              <div class="mt-2">
                  <router-link :to="{ name: 'Crear-DocumentCategory' }"
                    class="btn btn-primary text-white bg-blue-600 rounded-lg border-1 hover:bg-blue-900 mb-0 ml-auto">
                    Nueva Categoría
                  </router-link>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-12 py-2">
                <el-input
                    clearable
                    type="text"
                    placeholder="Nombre"
                    v-model="searchName"
                />
              </div>

              <div class="col-md-3">
                <button
                    @click="handleSearch"
                    class="
                      float-start
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border
                      hover:bg-blue-900 border-1
                      mt-2
                    ">
                  Buscar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                          text-uppercase text-custom text-xxs
                          font-weight-bolder
                          opacity-7
                        "
                    >
                      Nombre
                    </th>
                    <td class="d-none d-lg-table-cell text-center"></td>
                  </tr>
                </thead>

                <tbody v-if="entities && entities.length">
                  <tr v-for="item in entities" :key="item.id">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="{
                                  name: 'Detalle-DocumentCategory',
                                  params: { id: item.id },
                                }"
                            >
                              {{ item.name }}
                            </router-link>
                          </h6>
                            <div class="d-block d-lg-none">
                              <EditButton :id="item.id" route="Actualizar-DocumentCategory" />
                              <DeleteButton @click="handleDelete(item.id)" />
                            </div>
                        </div>
                      </div>
                    </td>
                    <td class="d-none d-lg-table-cell text-right pr-5">
                      <EditButton :id="item.id" route="Actualizar-DocumentCategory" />
                      <DeleteButton @click="handleDelete(item.id)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import { ref } from "vue";
import DeleteButton from "@/components/DeleteButton.vue";
import EditButton from "@/components/EditButton.vue";

const store = useStore();
const searchName = ref("");



const handleSearch = () => {
  store.dispatch("documentCategory/getEntities", {
    name: searchName.value,
  });
};

const getEntities = async () => {
  return await store.dispatch("documentCategory/getEntities");
};

onMounted(()=> {
  getEntities();
});

const entities = computed(
  () => store.state.documentCategory.entities.data
);

const deleteEntity = async (position) => {
  try {
    await store.dispatch("documentCategory/deleteEntity", position);
    await getEntities();
  } catch (error) {
    console.error(error);
  }
};

const handleDelete = (item) => {
  ElMessageBox.confirm("¿Está seguro que deseas eliminar la Categoría?")
    .then(() => {
      deleteEntity(item);
    })
    .catch((error) => {
      console.error(error);
    });
};
</script>
