<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4" v-if="currentEntity">
          <div class="card-header pt-1 pb-0">
            <div>
              <h3 class="card-title mb-0">
                Documento #"{{ currentEntity.title }}"
              </h3>

              <div class="sub-line"></div>

              <div class="row col-12 mt-3">
                <router-link :to="{ name: 'Documents' }"
                  class="col-md-2 col-6 col-md-offset-10 btn btn-primary btn-sm border-1 text-white bg-blue-600 rounded-lg hover:bg-blue-900 mb-0 ml-auto">
                  Ir al listado
                </router-link>
              </div>
            </div>
          </div>

          <div class="card-body">
             <div class="row">
               <div class="col-md-6">
                 <div class="card">
                    <div class="card-body">
                      <label for="name" class="form-control-label">
                        Título
                      </label>
                       <p>{{ currentEntity.title }}</p>

                        <label for="name" class="form-control-label">
                          Expediente
                        </label>
                      <div>
                        {{ currentEntity.expedient.code }}
                        {{ currentEntity.expedient.mainParent.fullName }} -
                        {{ currentEntity.expedient.secondaryParent.fullName }}
                      </div>


                      <label for="name" class="form-control-label">
                        Categoría
                      </label>
                      <p>{{ currentEntity.category.name }}</p>
                      <label for="name" class="form-control-label">
                        Usuario
                      </label>
                      {{ currentEntity.parentUser.fullName }}
                      <div>
                        <a
                            class="btn btn-link icon-primary px-3 mb-0 rounded text-primary"
                            :href="currentEntity.documentUrl"
                        >
                          <el-icon :size="25"
                                   color="#fff">
                            <Download />
                          </el-icon>
                          Descargar
                        </a>
                      </div>

                    </div>
                 </div>
               </div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import {computed, onMounted} from "vue";
import { useRoute } from "vue-router";
import {Download} from "@element-plus/icons-vue";
import {ElLoading} from "element-plus";

const route = useRoute();
const store = useStore();

const getCurrentEntity = async (id) => {
  const spin = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  await store.dispatch('document/getEntityById', id).finally(()=>spin.close());
};

const currentEntity = computed(() => store.state.document.currentEntity);

onMounted(() => {
  getCurrentEntity(route.params.id)
});
</script>
